import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");


function Preorder_order  () {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(6);
   const [order, setOrder] = useState([]);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(order.data);
  
  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    // Filter datas.data based on search query
    const filtered = order.filter(item =>
      item.order_id.toLowerCase().includes(query.toLowerCase()) ||
      item.date.toLowerCase().includes(query.toLowerCase()) ||
	item.name.toLowerCase().includes(query.toLowerCase()) ||
	item.number.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };
  

  if(userID <= 0 ){
    navigate('/login');
 }

  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.arivomkadai.com/preorder_orders/');
      setOrder(response.data);
	  const paid=response.data.data.filter(item =>item.payment_status ==='paid');
      setOrder(paid);
    //  console.log("orders:", paid);
	  console.log("orders:", response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(6); // Update rows to display only one row per page
  };

  // Render your component UI here
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-lg-8 mb-4 order-0">
                <div class="card">
                  <div class="d-flex align-items-end row">
                    <div class="col-sm-7">
                      <div class="card-body">
                        <h5 class="card-title text-primary p-2">Products</h5>
                        <p class="mb-4">
                          <span class="fw-bold">Arivomkadai:</span> Bridging the Gap Between Online and Arivomkadai Excellence products.
                        </p>
                      </div>
                    </div>
                    <div class="col-sm-5 text-center text-sm-left">
                      <div class="card-body pb-0 px-0 px-md-4">
                        <img
                          src="../assets/img/illustrations/man-with-laptop-light.png"
                          height="140"
                          alt="View Badge User"
                          data-app-dark-img="illustrations/man-with-laptop-dark.png"
                          data-app-light-img="illustrations/man-with-laptop-light.png"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
              <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
                <div class="card pb-4">
                  <div class="card-header d-flex align-items-center justify-content-between pb-0">
                    <div class="card-title mb-0">
                      <h5 class="m-0 me-2 mb-2">Arivomkadai Orders</h5>
                      <span>Total Orders</span>
                    </div>
                    <div class="avatar flex-shrink-0">
                            <img src="../assets/img/icons/unicons/cc-primary.png" alt="Credit Card" class="rounded" />
                          </div>
                    
                  </div>
                  <div class="card-body">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-column align-items-center gap-1">
                        <h2 class="mb-2"> {order && order.count}</h2>
                      </div>   </div>
                  </div>
                </div>
              </div>




              </div>


              </div>

            

              <div className="container-xxl flex-grow-1 container-p-y ">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Products</h4>
            <ul>
       
           <div className="card">
          <h5 className="card-header">Preorder Orders</h5>
          <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by customer name or order_id or date..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
            </div>
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>customer</th>
                  <th>order_id</th>
                  <th>Date</th>
                  <th>status</th>
                  <th>items</th>
                  <th>Amount</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(item => (
              <tr key={item.order_id}>
              <td>


                
                {item.profile ? (
                  <img src={`https://api.arivomkadai.com/customer_data/${item.profile}`} alt={item.name} className="w-px-40 h-px-40 rounded-circle" />
                ) : (
                  <img  src={'https://api.arivomkadai.com/customer_data/no_image.webp'}  alt="No Image" className="w-px-40 h-px-40 rounded-circle" />
                )}
                 <span class="fw-medium ml-1">{item.name}</span>
              </td>
              <td>{item.order_id}</td>
              <td>{item.date}</td>
              <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.status}</span></td>
              <td>{item.count}</td>
              <td>₹ {item.grand_total}</td>
              <td><a href={`preorder_details?id=${item.order_id}`} class="btn btn-sm btn-outline-primary">View</a>
                   </td>
            </tr>
            ))} </>
          ) : (<>
                {Array.isArray(order) && order.slice().reverse().slice(first, first + rows).map(item => (
                  <tr key={item.order_id}>
                    <td>


                      
                      {item.profile ? (
                        <img src={`https://api.arivomkadai.com/customer_data/${item.profile}`} alt={item.name} className="w-px-40 h-px-40 rounded-circle" />
                      ) : (
                        <img  src={'https://api.arivomkadai.com/customer_data/no_image.webp'}  alt="No Image" className="w-px-40 h-px-40 rounded-circle" />
                      )}
                       <span class="fw-medium ml-1">{item.name}</span>
                    </td>
                    <td>{item.order_id}</td>
                    <td>{item.date}</td>
                    <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.status}</span></td>
                    <td>{item.count}</td>
                    <td>₹ {item.grand_total}</td>
                    <td><a href={`preorder_details?id=${item.order_id}`} class="btn btn-sm btn-outline-primary">View</a>
                         </td>
                  </tr>
                ))}
                </>)}
              </tbody>
            </table>
            <Paginator first={first} rows={rows} totalRecords={order ? order.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>


          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  );
};

export default Preorder_order;
