import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';

 function Offlinebanner() {
    const userID = ReactSession.get('userId');
	const [oldData, setOldData] = useState({});
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(15);
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [text, setText] = useState(""); 
const [heading, setHeading] = useState("");  
// console.log(heading);  




  if(userID <= 0 ){
    navigate('/login');
 }
 
     useEffect(() => {
       const heading = oldData?.heading;
      const text = oldData?.text;
      const file = oldData?.image;
      
	  
  
      setHeading(heading);
      setText(text);
      setFile(file);
     
    }, [oldData]);
 
 
 
 
     const handleFile = (event) => {
        setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
    };
   const handletext = (event) => {
      setText(event.target.value); // Changed setInput to setLink
    };
  
  
    const handleheading = (event) => {
      setHeading(event.target.value);
    }
  

  const handleSubmit = async(event) => {
    event.preventDefault();
	
	
	 const formData = new FormData();
	  formData.append('id', '2');
      formData.append('heading', heading);
      formData.append('text', text);
      formData.append('images', file);
    
	
	// console.log(FormData);
    
    try {
        const response = await axios.post('https://api.arivomkadai.com/onlinebanner/', formData);
        
        // console.log(response.data);
        fetchData();
        event.target.reset();

       alert('added sucessfully');
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
    }
  }


 
    const fetchData = async () => {
		
      try {
        // Make API call to fetch old data
        const response = await axios.get(`https://api.arivomkadai.com/onlinebanner/`);
		const datas = response.data.data.find(item => item.id === '2');
        setOldData(datas); 
         //console.log(datas);
      } catch (error) {
        console.error('Error fetching old data:', error);
      }
    };

    useEffect(() => {
    fetchData();
  }, []);


  
  

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };


   // console.log(testinominal.data);

  return (
    <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Banner</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Banner</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                                <div className="row">
                                                <div className="mb-3 col-md-4">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="images"
                                                            id="images"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile}
                                                        />
                                                    </div>
													
													
													 <div className="mb-3 col-md-4">
                                                        <label htmlFor="image" className="form-label">Banner text</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="text"
                                                            value={text}
                                                            onChange={handletext}
                                                        />
                                                    </div>
													
													 <div className="mb-3 col-md-4">
                                                        <label htmlFor="image" className="form-label">Banner Heading</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="heading"
                                                            value={heading}
                                                            onChange={handleheading}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-4">
                                                    <div className="mt-4">
                                                    <button type="submit" className="btn btn-primary me-2">Edit</button>
                                                </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </form>



                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
  )
}
export default Offlinebanner;