import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReactSession } from 'react-client-session';

 function Preorderreport() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(15);
  const [filter, setFilter] = useState({});
  const navigate = useNavigate();
  const[input, setInput] = useState({s_date:'', e_date: '', cat: 'all'});
  const [error, setError] = useState('');


  if(userID <= 0 ){
    navigate('/login');
 }
 const handleinputs = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput(values => ({...values, [name]: value}))
  }
  //console.log(input);

  const handleSubmit = async(event) => {
    event.preventDefault();
    
    try {
        const response = await axios.post('https://api.arivomkadai.com/preorderfilter/', input);
        setFilter(response.data);
        //console.log(response.data);

      // navigate(`/addvariations?id=${response.data.productId}`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
  }

  

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };

  
   
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};

  
 const handleDownloadPDF = async () => {
  openModal();
  const input = document.getElementById('pdf-content');
  input.classList.remove('hide');

  const pdf = new jsPDF({
    orientation: 'portrait', // Use 'portrait' or 'landscape' as per your requirement
    unit: 'pt', // Set unit to millimeters
    format: 'a4', // Set format to A4
  });
  const inputHeight = input.clientHeight;
  const pageHeight = pdf.internal.pageSize.getHeight()+600;
  let currentPosition = 0;
  const scaleFactor = 4; 
  
  // Increase this value for higher resolution and better quality

  while (currentPosition < inputHeight) {
    const canvas = await html2canvas(input, {
      height: Math.min(pageHeight),
      
      y: currentPosition,
      scrollY: -currentPosition,
      scale: scaleFactor
    });

    

    
    const imgData = canvas.toDataURL('image/png', 4);
    pdf.addImage(imgData, 'PNG', 5, 5, 580, pdf.internal.pageSize.getHeight(), '', 'FAST');
    //currentPosition +=canvas.height;
    currentPosition += pageHeight;
    if (currentPosition < inputHeight) {
      pdf.addPage();
    }
   
  }
 pdf.save(filter && Array.isArray(filter)  && filter.date+'-'+filter.date+'.pdf');
  
  input.classList.add('hide');
  closeModal();
};


   //console.log(filter.data);

  return (
    <>
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Preorder Report</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Preorder Report</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Start Date</label>
                                                       
                                                    <input aria-label="Date" type="date"   className="form-control"
                                                            id="name"
                                                            name="s_date"
                                                            required
                                                            onChange={handleinputs}
                                                            />
                                                    </div>


                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">End Date</label>
                                                       
                                                    <input aria-label="Date" type="date"   className="form-control"
                                                            id="name"
                                                            name="e_date"
                                                            
                                                            onChange={handleinputs}
                                                            required
                                                            />
                                                    </div>
                                                    
                                                    
													
                                                </div>
                                                <div className="mt-2">
                                                    <button type="submit" className="btn btn-primary me-2">Filter</button>
                                                </div>
                                            </form>




                                            <div className="container-xxl flex-grow-1 container-p-y ">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Preorder Reports</h4>
            <ul>
       
           
           <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <button onClick={handleDownloadPDF} className="btn btn-primary me-2 mt-2 ml-5"> <i class="fa fa-download"></i> Download Reports</button>
                                              
                        
            </div>
            <div className="card" id="pdf-content">
          <h5 className="card-header">Preorder Reports</h5>
          
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Product</th>
                  <td>Price</td>
                  <th>qty</th>
                
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {filter && filter.data && filter.data.map((item, index) => (
  <tr key={index}>
    <td>{item.date}</td>
    <td>{item.product}</td>
    <td>{item.price}</td>
    <td>{item.qty}</td>
  </tr>
))}
              </tbody>
            </table>
         <Paginator first={first} rows={rows} totalRecords={filter.data ? filter.data.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        </>
  )
}
export default Preorderreport;