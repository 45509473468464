import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import { ReactSession } from 'react-client-session';
import ContentEditable from 'react-contenteditable';
import * as XLSX from 'xlsx';


function Reportoffline() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(15);
    const [order, setOrder] = useState([]);
  const navigate = useNavigate();
  const[input, setInput] = useState({s_date:null, e_date: null});
  const [error, setError] = useState('');
  const [activeTab, setActiveTab] = useState('order');
  const [hovered, setHovered] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [filteredDatas, setFilteredDatas] = useState(filteredData);
  const [datas, setData] = useState([]);
  const [searchQuerys, setSearchQuerys] = useState('');
//  const [filteredDatastock, setFilteredDatastock] = useState(datas.data);
  
 
  console.log('filtereddata',filteredData);

  


  if(userID <= 0 ){
    navigate('/login');
 }
 const handleinputs = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInput(values => ({...values, [name]: value}))
  }
  //console.log(input);


  // Function to handle search input change
  const handleSearchInputChangeReports = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    // Filter datas.data based on search query
    const filtered =filteredData.filter(item =>
      item.order_id.toLowerCase().includes(query.toLowerCase()) ||
      item.date.toLowerCase().includes(query.toLowerCase()) ||
    item.name.toLowerCase().includes(query.toLowerCase()) ||
    item.number.toLowerCase().includes(query.toLowerCase())
    
    
    
    );
    setFilteredDatas(filtered);
  };
 




  // const handleSearchInputChangeInvoice = (event) => {
  //   const query = event.target.value;
  //   setSearchQuery(query);
  
  //   // Filter datas.data based on search query
  //   const filtered = order.filter(item =>
  //     item.id.toLowerCase().includes(query.toLowerCase()) ||
  //     item.date.toLowerCase().includes(query.toLowerCase()) ||
  //   item.name.toLowerCase().includes(query.toLowerCase()) 
    
    
    
  //   );
  //   setFilteredData(filtered);
  // };



  // const handleSearchInputChangeItem = (event) => {
  //   const query = event.target.value;
  //   setSearchQuery(query);
  
  //   // Filter datas.data based on search query
  //   const filtered = order.filter(item =>
  //     item.orderData[0].code.toLowerCase().includes(query.toLowerCase()) ||
  //     item.orderData[0].product.toLowerCase().includes(query.toLowerCase()) ||
  //   item.date.toLowerCase().includes(query.toLowerCase()) 
    
    
    
  //   );
  //   setFilteredData(filtered);
  // };

 
  
  // // Function to handle search input change
  // const handleSearchInputChangestock = (event) => {
  //   const query = event.target.value;
  //   setSearchQuerys(query);

  //   // Filter datas.data based on search query
  //   const filtered = datas.data.filter(item =>
  //     item.product_code.toLowerCase().includes(query.toLowerCase()) ||
  //     item.content.toLowerCase().includes(query.toLowerCase())
  //   );
  //   setFilteredDatastock(filtered);
  //   console.log(filtered);
  // };







  const handleSubmit = async(event) => {
    event.preventDefault();
    
    try {
        const response = await axios.post('https://api.arivomkadai.com/filter/', input);
        const paid=response.data.data.filter(item =>item.payment_status ==='paid');
        setOrder(paid);
        setFilteredData(paid);
       // console.log(response.data);

      // navigate(`/addvariations?id=${response.data.productId}`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
  }


  // const fetchData = async () => {
  //   try {
  //     const response = await axios.get('https://api.arivomkadai.com/offlineorders/');
	//   const paid=response.data.data.filter(item =>item.payment_status ==='paid');
  //     setOrder(paid);
  //     setFilteredData(paid);
     
  //     //console.log("orders:", paid);
	//  console.log("orders:", response.data);
  //   } catch (error) {
  //     console.error("Error fetching data:", error);
  //   }
  // };
  // useEffect(() => {
  //   fetchData();
  // }, []);




  
  

  

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };

  
   
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};
const handleDownloadExcel = () => {
  openModal();
  
  const input = document.getElementById('order');
  input.classList.remove('hide');
  
  // Assume that the input element contains a table
  const table = input.querySelector('table');
  if (!table) {
    console.error('No table found inside the specified element');
    return;
  }
  
  // Convert table to worksheet
  const ws = XLSX.utils.table_to_sheet(table);

  // Create a new workbook
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
  // Generate the Excel file
  XLSX.writeFile(wb, `${activeTab}.xlsx`);
  
  input.classList.add('hide');
  closeModal();
};


   //console.log(filter.data);

  return (
    <>
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Arivomkadai Report</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                    
                                        <h5 className="card-header">Arivomkadai Report</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                                <div className="row">
                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Start Date</label>
                                                       
                                                    <input aria-label="Date" type="date"   className="form-control"
                                                            id="name"
                                                            name="s_date"
                                                            required
                                                            onChange={handleinputs}
                                                            />
                                                    </div>


                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">End Date</label>
                                                       
                                                    <input aria-label="Date" type="date"   className="form-control"
                                                            id="name"
                                                            name="e_date"
                                                            
                                                            onChange={handleinputs}
                                                            required
                                                            />
                                                    </div>


                                                    
                                                    
                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3 mt-4">
                                                        
                                                    <button type="submit" className="btn btn-primary me-2">Filter</button>
                                                </div>
													                     

                                                </div>
                                                
                                            </form>



            
          <div  style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <button onClick={handleDownloadExcel} className="btn btn-primary me-2 mt-5 ml-5"> <i class="fa fa-download"></i> Download Reports</button>
                                              
                        
            </div>



            
          

                                           


          <div className="row">

         


              
                    <div className="container-xxl flex-grow-1 container-p-y">
            <ul>
       
          
           
            <div className="card"  id="order"   >
          <h5 className="card-header">Order Reports</h5>


          <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by orderID or customer or item or contact number..."
              value={searchQuery}
              onChange={handleSearchInputChangeReports}
            />
          </div>
            </div>
         
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                <th>S.No</th>
                <th>OrderID</th>
                  <th>Date</th>
                  <th>Customer</th>
                  <th>Customer Number</th>
                  <th>Customer Location</th>
                  <th>Items</th>
                  <th>Purchase value</th>
                  <th>Order value</th>
                  <th>Gst value</th>
                  <th>Delivery value</th>
                  <th>Status</th>
                
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {searchQuery && filteredDatas.length > 0 ? (
            <>  {filteredDatas.map((item, index)=> (
              <tr  key={item.order_id}>
              <td>{index+1}</td>
              <td>{item.order_id}</td>
             <td>{item.date}</td>
             <td>{item.name}</td>
             <td>{item.number}</td>
             <td>{item.state}</td>
             <td>{item.count}</td>
             <td>{item.orderData[0].p_price}</td>
             <td>{item.grand_total}</td>
             <td>{item.orderData[0].gst}</td>
             <td>{item.shipping}</td>
               
             <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.status}</span></td>
                            
             
           </tr>
                ))} </>
              ) : searchQuery && filteredDatas.length === 0 ? (
            <tr >
                         <td>No products found </td>
                       </tr>
         ) : (<>
              {Array.isArray(order) && order.slice().reverse().slice(first, first + rows).map((item, index) => (
  <tr  key={item.order_id}>
     <td>{index+1}</td>
     <td>{item.order_id}</td>
    <td>{item.date}</td>
    <td>{item.name}</td>
    <td>{item.number}</td>
    <td>{item.state}</td>
    <td>{item.count}</td>
    <td>{item.orderData[0].p_price}</td>
    <td>{item.grand_total}</td>
    <td>{item.orderData[0].gst}</td>
    <td>{item.shipping}</td>
      
    <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.status}</span></td>
                   
    
  </tr>
 ))}
  </>)}
              </tbody>
            </table>
            <Paginator first={first} rows={rows} totalRecords={order ? order.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>
                
             
                {/* <div
                    style={activeTab === 'item' ? activeTabContentStyle : tabContentStyle}
                >
                   <div className="container-xxl flex-grow-1 container-p-y">
            <ul>
       
          
           
           
            <div className="card"  id="item"   >
          <h5 className="card-header">Item Report</h5>
               

          <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by  item code or date or description..."
              value={searchQuery}
              onChange={handleSearchInputChangeItem}
            />
          </div>
            </div>
          
         
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Date</th>
                  <th>Item Code</th>
                  <th>Item Description</th>
                  <th>Items</th>
                  <th>Item Value</th>
                
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">

              {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(item => (
              <tr  key={item.order_id}>
              <td>{item.date}</td>
             <td>{item.orderData[0].code}</td>
             <td className="text-wrap text-break">{item.orderData[0].product.length > 40 ? `${item.orderData[0].product.slice(0, 40)}...` : item.product}</td>
                           
             <td>{item.count}</td>
             <td>{item.orderData[0].price}</td>
                   
             
           </tr>
                ))} </>
              ) : searchQuery && filteredData.length === 0 ? (
            <tr >
                         <td>No products found </td>
                       </tr>
         ) : (<>
              {Array.isArray(order) && order.slice().reverse().slice(first, first + rows).map(item => (
  <tr  key={item.order_id}>
     <td>{item.date}</td>
    <td>{item.orderData[0].code}</td>
    <td className="text-wrap text-break">{item.orderData[0].product.length > 40 ? `${item.orderData[0].product.slice(0, 40)}...` : item.product}</td>
                  
    <td>{item.count}</td>
    <td>{item.orderData[0].price}</td>
          
    
  </tr>
 ))}
 </>
         )}
              </tbody>
            </table>
            <Paginator first={first} rows={rows} totalRecords={order ? order.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>
                </div>
                <div
                    style={activeTab === 'stock' ? activeTabContentStyle : tabContentStyle}
                >
                   
                   <div className="container-xxl flex-grow-1 container-p-y">
            <ul>
       
          

            
          <div className="demo-inline-spacing mt-2" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
          <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by item code or description..."
              value={searchQuerys}
              onChange={handleSearchInputChangestock}
            />
          </div>
            </div>
           
            <div className="card"  id="stock"   >
          <h5 className="card-header">Stock Report</h5>

          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                <th>Item Code</th>
                <th>Item Description</th>
                <th>Stock In Hand</th>
                
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">

              {searchQuerys && filteredDatastock.length > 0 ? (
            <>  {filteredDatastock.map(item => (
              <tr  key={item.p_id}>
              <td>{item.product_code}</td>
              <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                                   {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 20)}`} />)} 
                                   <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                           {isReadMoreMap[item.p_id] ? ' Show Less' : ' ...Read More'}
                                   </a>
                               </td>
             <td>{item.stock+' '+item.stockType}</td>
            
         
         
             
           </tr>
                ))} </>
              ) : searchQuerys && filteredDatastock.length === 0 ? (
            <tr >
                         <td>No products found </td>
                       </tr>
         ) : (<>
              {datas && datas.data && datas.data.slice(first, first + rows).map(item => (
  <tr  key={item.p_id}>
     <td>{item.product_code}</td>
     <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                          {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 20)}`} />)} 
                          <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                  {isReadMoreMap[item.p_id] ? ' Show Less' : ' ...Read More'}
                          </a>
                      </td>
    <td>{item.stock+' '+item.stockType}</td>
   


    
  </tr>
 ))}
 </>
         )}
              </tbody>
            </table>
            <Paginator first={first} rows={rows} totalRecords={order ? order.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>



                </div> */}
            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
        </>
  )
}

export default Reportoffline;
