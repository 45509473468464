import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';

 function Testinominal() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(15);
  const [testinominal, setTestinominal] = useState({});
  const navigate = useNavigate();
  const [file, setFile] = useState(null); 



  if(userID <= 0 ){
    navigate('/login');
 }
 const handleFile = (event) => {
    setFile(event.target.files[0]); // Only taking the first file if multiple files are selected
};
  

  const handleSubmit = async(event) => {
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('image', file);
    try {
        const response = await axios.post('https://api.arivomkadai.com/testimonial/', formData);
        
        //console.log(response.data);
        fetchData();
        event.target.reset();

       alert('added sucessfully');
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
    }
  }


 
    const fetchData = async () => {
      try {
        // Make API call to fetch old data
        const response = await axios.get(`https://api.arivomkadai.com/testimonial/`);
        setTestinominal(response.data); 
       // console.log(response.data);
      } catch (error) {
        console.error('Error fetching old data:', error);
      }
    };

    useEffect(() => {
    fetchData();
  }, []);


  const removetestinominal = async (productId) => {
    //console.log('Deleting product with ID:', productId); // Log the product ID
    try {
      const response = await axios.put('https://api.arivomkadai.com/testimonial/', {id: productId});
      //console.log(response.data);
      fetchData();
     
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  
  

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };


   //console.log(testinominal.data);

  return (
    <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Testinominal</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Testinominal</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                                <div className="row">
                                                <div className="mb-3 col-md-4">
                                                        <label htmlFor="image" className="form-label">Image</label>
                                                        <input
                                                            className="form-control"
                                                            type="file"
                                                            name="image"
                                                            id="image"
                                                            accept="image/png, image/jpeg, image/jpg, image/pdf"
                                                            onChange={handleFile}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-4">
                                                    <div className="mt-4">
                                                    <button type="submit" className="btn btn-primary me-2">Add</button>
                                                </div>
                                                    </div>
                                                    
                                                </div>
                                                
                                            </form>




                                            <div className="container-xxl flex-grow-1 container-p-y ">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Testinominal</h4>
            <ul>
       
           <div className="card">
          <h5 className="card-header">Testinominal</h5>
         
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                <th>Image</th>
                <th>Action</th>
                
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {testinominal && testinominal.data && testinominal.data.map(item => (
  <tr key={item.id}>
    <td>


                        
{item.image ? (
  <img src={`https://api.arivomkadai.com/testimonial_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
) : (
  <img  src={'https://api.arivomkadai.com/testimonial_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
)}
</td>
   
    <td><a  onClick={() => removetestinominal(item.id)} class='btn btn-danger bg-gradient-danger'> <i class="menu-icon tf-icons bx bx-trash"></i></a>
										</td>
  </tr>
))}
              </tbody>
            </table>
         <Paginator first={first} rows={rows} totalRecords={testinominal.data ? testinominal.data.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
  )
}
export default Testinominal;