import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Login() {
  const [input, setInput] = useState({});
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  const handleChange=(event)=>{
    const name = event.target.name;
    const value =event.target.value;
    setInput(values => ({...values, [name]: value}))
}
   // console.log(input);
    const handleSubmit =async (event) => {
        event.preventDefault();
        try {
          const response = await axios.post('https://api.arivomkadai.com/login/', input);

          // Assuming response.data contains user information upon successful login
          const userData = response.data;
        //  console.log(userData);
          if (response.data.status === 'success' ) {
            ReactSession.set("arvUserId", response.data.data[0].user_id);
           navigate('/dashboard');
          } else {
            const errors = response.data.message;
            setMessage(errors);
          }
      } catch (error) {
          // Handle error
          console.error('There was a problem with login:', error);
          setError('Invalid email or password');
      }
    }
  return (
    <div class="container-xxl">
    <div class="authentication-wrapper authentication-basic container-p-y">
      <div class="authentication-inner">
       
        <div class="card">
          <div class="card-body">
           
            <div class="app-brand justify-content-center">
              <a href="index.html" class="app-brand-link gap-2">
               
                <span class="app-brand-text demo text-body fw-bolder"> <img src="logo.png" alt="logo"/></span>
              </a>
            </div>
               
            <h4 class="mb-2">Welcome to Arivomkadai! 👋</h4>
            <p class="mb-4">Please sign-in to your account</p>

            <form class="mb-3" onSubmit={handleSubmit}>
              <div class="mb-3">
                <label for="email" class="form-label">Email or Username</label>
                <input
                  type="text"
                  class="form-control"
                  id="email"
                  name="email"
                  placeholder="Enter your email or username"
                  autoFocus
                  value={input.email} 
                  onChange={handleChange}/>
              </div>
              <div class="mb-3 form-password-toggle">
                
                <div class="input-group input-group-merge">
                  <input
                    type="password"
                    id="password"
                    class="form-control"
                    name="password"
                    placeholder="Enter password"
                    aria-describedby="password"
                    value={input.password}
                    onChange={handleChange}
                  />
                  
                </div>
              </div>
              
              <div class="mb-3">
                <button class="btn btn-primary d-grid w-100">Sign in</button>
              </div>
            </form>
         
         
          </div>

          {message && message.length > 0 && (
                                              <p className="text-danger">{message}</p>
                                            )}

        </div>
        
      </div>
    </div>
  </div>
  )
}
export default Login;