import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");


 function Editpreorderproducts() {
  const userID = ReactSession.get('userId');
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('id');
    //console.log(productId); 
    const [oldData, setOldData] = useState({});
	const [code, setCode] = useState("");
    const [des, setdes] = useState('');
    const [name, setName] = useState("");
	 const [keyword, setKeyword] = useState("");
    const [price, setPrice] = useState('');
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [video, setVideo] = useState(null);
    const [error, setError] = useState('');
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [breadth, setBreadth] = useState("");
	 const [selectedVideo, setselectVideo] = useState('video');
  
  
     const selectVideo =() =>{
		if(selectedVideo === 'video'){
			setselectVideo('url');
		}else{
			setselectVideo('video');
		}
	};
  
    const navigate = useNavigate();
  
    if(userID <= 0 ){
      navigate('/login');
   }

   
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};
  
    useEffect(() => {
      const des = oldData?.data?.[0]?.content;
      const name= oldData?.data?.[0]?.product;
      const price = oldData?.data?.[0]?.price;
      const file = oldData?.data?.[0]?.file;
      const file1 = oldData?.data?.[0]?.file1;
      const file2 = oldData?.data?.[0]?.file2;
      const file3 = oldData?.data?.[0]?.file3;
      const video = oldData?.data?.[0]?.video;
      const width= oldData?.data?.[0]?.width;
      const height= oldData?.data?.[0]?.height;
      const breadth= oldData?.data?.[0]?.breadth;
      const weight= oldData?.data?.[0]?.weight;
	  const product_code = oldData?.data?.[0]?.product_code;
	   const keyword = oldData?.data?.[0]?.keyword;
  
      setName(name);
      setWidth(width);
      setHeight(height);
      setBreadth(breadth);
      setWeight(weight);
      setPrice(price);
      setFile(file);
      setFile1(file1);
      setFile2(file2);
      setFile3(file3);
      setVideo(video);
      setdes(des);
	  setCode(product_code);
	   setKeyword(keyword);
  
    }, [oldData]);
  
  
  
    useEffect(() => {
      const fetchOldData = async () => {
        try {
          // Make API call to fetch old data
          const response = await axios.get(`https://api.arivomkadai.com/editpreorderproduct/?id=${productId}`);
          const oldData = response.data;
          setOldData(oldData); // Set oldData state
          //console.log(oldData);
        } catch (error) {
          console.error('Error fetching old data:', error);
        }
      };
  
      // Call the fetchOldData function
      fetchOldData();
    }, []);
  
    const handleCode = (event) => {
      setCode(event.target.value); 
    };
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    }
	
	const handleKeyword = (event) => {
        setKeyword(event.target.value); // Changed setInput to setLink
    };
	
	
    const handlePriceChange = (event) => {
      setPrice(event.target.value);
    }
   
    const handleDesChange = (event) => {
      setdes(event.target.value);
	  if (typeof window !== 'undefined') {
    ClassicEditor
      .create(document.querySelector('#classic-editor'), {
        // CKEditor configuration here
      })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          const data = editor.getData();
          setdes(data);
        });
      })
      .catch(error => {
        console.error('Error initializing CKEditor:', error);
      });
  }
    }
    const handleFileChange = (event) => {
     
      const file = event.target.files[0];
      setFile(file);
    }
  
    const handleFileChange1 = (event) => {
    
      const file1 = event.target.files[0];
      setFile1(file1);
    }
  
    const handleFileChange2 = (event) => {
      
      const file2 = event.target.files[0];
      setFile2(file2);
    }
  
    const handleFileChange3 = (event) => {
    
      const file3 = event.target.files[0];
      setFile3(file3);
    }
  
    const handleVideoChange = (event) => {
     
      const video = event.target.files[0];
      setVideo(video);
    }


    
    const handleweight = (event) => {
      setWeight(event.target.value);
  };

  const handleheight = (event) => {
      setHeight(event.target.value);
  };


  const handlebreadth = (event) => {
      setBreadth(event.target.value);
  };

  const handlewidth = (event) => {
      setWidth(event.target.value);
  };
  const handleUrl = (event) => {
        setVideo(event.target.value); // Changed setInput to setLink
    };
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const formData = new FormData();
      formData.append('id', productId);
      
    
      // Check if content has changed
      formData.append('content', des);
      formData.append('images', file);
      formData.append('image1', file1);
      formData.append('image2', file2);
      formData.append('image3', file3);
      formData.append('name', name);
      formData.append('price', price);
      formData.append('video', video);
      formData.append('height', height);
      formData.append('width', width);
      formData.append('breadth', breadth);
      formData.append('weight', weight);
	  formData.append('code', code);
	  formData.append('keyword', keyword);
      
      openModal();
      try {
    
        const response = await axios.post('https://api.arivomkadai.com/editpreorderproduct/', formData);
        //console.log(response.data)
        navigate('/preorder');
      } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
      }
      closeModal();
    }
  
    return (
      <>
      <div id="onloadModal" >
      <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}}>Product Loading.</p>
      </div>
       </div>
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside/>
          <div className="layout-page">
            <Navbar/>
            <div className="content-wrapper">


           



              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Update Product</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <h5 className="card-header">Update Product</h5>
                      <div className="card-body">
                        <form id="formAccountSettings" onSubmit={handleSubmit}>
                          <div className="row">
						  <div className="mb-3 col-md-4">
                                                        <label htmlFor="link" className="form-label">Product Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            value={code}
                                                            onChange={handleCode}
                                                        />
														</div>
                          <div className="mb-3 col-md-4">
                                                          <label htmlFor="link" className="form-label">Name</label>
                                                          <input
                                                              className="form-control"
                                                              type="text"
                                                              id="name"
                                                              name="name"
                                                              value={name}
                                                              onChange={handleNameChange}
                                                          />
                                                      </div>
													  
													   <div className="mb-3 col-md-4">
                                                          <label htmlFor="link" className="form-label">Price</label>
                                                          <input
                                                              className="form-control"
                                                              type="text"
                                                              id="price"
                                                              name="price"
                                                              value={price}
                                                              onChange={handlePriceChange}
                                                          />
                                                      </div>
  
                           
                            <div className="mb-3 col-md-12">
                              <label htmlFor="lastName" className="form-label">Description</label>
                              <input
                                className="form-control"
                                type="text"
								id="classic-editor"
                                name="des"
                                value={des}
                                onChange={handleDesChange}
                              />
                            </div>
							
							{/* <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Keywords</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="keyword"
                                                            id="keyword"
                                                            value={keyword}
                                                            onChange={handleKeyword}
                                                        />
                                                    </div> */}
  
  
                           
                                             

                                                     
                                                     
                                                     <div className="mb-3 mt-4 col-md-4">
													   <button type="button"  className={selectedVideo === 'video' ? 'btn btn-primary me-2' : 'btn btn-secondary me-2'} onClick={selectVideo}>Video File</button>
													   <button type="button" className={selectedVideo === 'video' ? 'btn btn-secondary me-2' : 'btn btn-primary me-2'} onClick={selectVideo}>YouTube Video</button>
                                                    </div>
                                                    
                                                    
                             {selectedVideo === 'video' ? (     
<div className="mb-3 col-md-8">
                                                          <label htmlFor="des" className="form-label">Video</label>
                                                          <input
                                                              className="form-control"
                                                              type="file"
                                                              name="video"
                                                              id="video"
                                                              accept="mp4"
                                                              onChange={handleVideoChange}
                                                          />
                                                      </div>							 
                           
							):(
													
													<div className="mb-3 col-md-8">
                                                        <label htmlFor="des" className="form-label">Video Url</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="url"
                                                            id="url"
															 value={video}
                                                            onChange={handleUrl}
                                                            
                                                        />
                                                    </div>
													)}                  
                                                    
                                                      
                            <div className="mb-3 col-md-6">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="images"
                                id="images"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="image1"
                                id="image1"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange1}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="image2"
                                id="image2"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange2}
                              />
                            </div>
                            <div className="mb-3 col-md-6">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="image3"
                                id="image3"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange3}
                              />
                            </div>
							
							 <h5 className="card-header">Shipping Details Update</h5>
							
							 <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Breadth</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="breadth"
                                                            id="breadth"
                                                            value={breadth}
                                                            onChange={handlebreadth}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Width</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="width"
                                                            id="width"
                                                            value={width}
                                                            onChange={handlewidth}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Height</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="height"
                                                            id="height"
                                                            value={height}
                                                            onChange={handleheight}
                                                        />
                                                    </div>

                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="des" className="form-label">Weight</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="weight"
                                                            id="weight"
                                                            value={weight}
                                                            onChange={handleweight}
                                                        />
                                                    </div>
                          
                          </div>
                          <div className="mt-2">
                            <button type="submit" className="btn btn-primary me-2">Save changes</button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
            <div className="content-backdrop fade"></div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      </>
  )
}
export default Editpreorderproducts;
