import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";

import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

function Offlinegiftbox() {
    const userID = ReactSession.get('userId');
    const navigate = useNavigate();
    const [price, setPrice] = useState("");
    
    
    if(userID <= 0 ){
        navigate('/login');
     }

      useEffect(() => {
        const fetchOldData = async () => {
          try {
            // Make API call to fetch old data
            const response = await axios.get(`https://api.arivomkadai.com/giftBox/`);
            const price = response.data.price;
            setPrice(price); 
          } catch (error) {
            console.error('Error fetching old data:', error);
          }
        };
    
        // Call the fetchOldData function
        fetchOldData();
      }, []);

      
    const handlePrice = (event) => {
        setPrice(event.target.value); // Changed setInput to setLink
    };
    const handleSubmit = async (event) => {
        event.preventDefault();
        const formData = { price: price };
        //console.log(formData);
        try {
            const response = await axios.post('https://api.arivomkadai.com/giftBox/', formData);
            //console.log( response.data);
            alert("update sucessfully");
            // Redirecting to home page
             //navigate('/product');
        } catch (error) {
            // Handle error
            console.error('There was a problem with login:', error);
        }
    };
  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
        <Aside/>
        <div className="layout-page">
            <Navbar/>
            <div className="content-wrapper">
                <div className="container-xxl flex-grow-1 container-p-y">
                    <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Gift Box</h4>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card mb-4">
                                <h5 className="card-header">Gift Box</h5>
                                <div className="card-body">
                                   
                                        <div className="row">
                                            <div className="mb-3 col-md-3 col-xl-6 col-lg-6">
                                                <label htmlFor="link" className="form-label">Price</label>
                                                <input
                                                    className="form-control"
                                                    type="number"
                                                    id="price"
                                                    name="price"
                                                    value={price}
                                                    onChange={handlePrice}
                                                />
                                            </div>
                                            
                                        </div>
                                        <div className="mt-2">
                                            <button type="submit"onClick={handleSubmit} className="btn btn-primary me-2">Edit</button>
                                        </div>
                                 
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer/>
                <div className="content-backdrop fade"></div>
            </div>
        </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
</div>
  )
}
export default  Offlinegiftbox;
