import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import { ReactSession } from 'react-client-session';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
ReactSession.setStoreType("localStorage");


 function Editofflineproduct() {
  const userID = ReactSession.get('userId');
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('id');
    //console.log(productId); 
    const [oldData, setOldData] = useState({});
    const [des, setdes] = useState('');
    const [code, setCode] = useState("");
    const [name, setName] = useState("");
    const [price, setPrice] = useState('');
	 const [keyword, setKeyword] = useState("");
    const [cat, setCat] = useState('');
    const [file, setFile] = useState(null);
    const [file1, setFile1] = useState(null);
    const [file2, setFile2] = useState(null);
    const [file3, setFile3] = useState(null);
    const [video, setVideo] = useState(null);
    const [error, setError] = useState('');
    const [weight, setWeight] = useState("");
    const [height, setHeight] = useState("");
    const [width, setWidth] = useState("");
    const [breadth, setBreadth] = useState("");
    const [stock, setStock] = useState("");
    const [messure, setMessure] = useState("");
	const [productCode, setProductCode] = useState(true);
	const [selectedVideo, setselectVideo] = useState('video');




    const [oldvariations, setOldVararations] = useState([]);

   

    useEffect(() => {
      const fetchOldData = async () => {
        try {
          // Make API call to fetch old data
          const response = await axios.get(`https://api.arivomkadai.com/editofflineproduct/?id=${productId}`);
          const oldData = response.data;
          setOldData(oldData); 

          const newVariations = response.data.data[0].variations.map(variant => ({
            p_id: variant.p_id,
            weights: variant.weights,
            value: variant.value,
            price: variant.price,
            mrp: variant.mrp,
            p_price: variant.p_price,
            breadth: variant.breadth,
            shippingweight: variant.shippingweight,
            height: variant.height,
            width: variant.width,
            gst: variant.gst
          }));

          setOldVararations(...oldvariations, newVariations);
         //console.log(oldData);
        } catch (error) {
          console.error('Error fetching old data:', error);
        }
      };
  
      // Call the fetchOldData function
      fetchOldData();
    }, []);


        const selectVideo =() =>{
		if(selectedVideo === 'video'){
			setselectVideo('url');
		}else{
			setselectVideo('video');
		}
	};

    const handleChangeAdd = () => { 
        const newVar = {p_id: productId, weights:'Select Mesurement', value:'', price:'',  mrp:'', p_price:''};
        setOldVararations([...oldvariations, newVar])
        //console.log(oldvariations);
      }
  
      const handleChangeDelete = (index) => {
        setOldVararations(prevData => { 
          const updatedData = [...prevData.slice(0, index), ...prevData.slice(index + 1)]; 
          return updatedData; 
        });
        
      }

      const handleChangeValue = (e, index) => { 
        const newValue = e.target.value; 
        setOldVararations(prevVariations => { 
          const updatedVariations = [...prevVariations]; 
          updatedVariations[index].value = newValue; 
          return updatedVariations;
          
      }); }

      const handleChangeGst = (e, index) => { 
        const newValue = e.target.value; 
        setOldVararations(prevVariations => { 
          const updatedVariations = [...prevVariations]; 
          updatedVariations[index].gst = newValue; 
          return updatedVariations;
          
      }); }
  
      const handleChangeWeight = (e, index) => { 
          const newValue = e.target.value; 
          setOldVararations(prevVariations => { 
            const updatedVariations = [...prevVariations]; 
            updatedVariations[index].weights = newValue; 
            return updatedVariations;
            
      }); }
  
      const handleChangePrice = (e, index) => { 
            const newValue = e.target.value; 
            setOldVararations(prevVariations => { 
              const updatedVariations = [...prevVariations]; 
              updatedVariations[index].price = newValue; 
              return updatedVariations;
          
      }); }
	  
	  const handleChangemrp = (e, index) => { 
            const newValue = e.target.value; 
            setOldVararations(prevVariations => { 
              const updatedVariations = [...prevVariations]; 
              updatedVariations[index].mrp = newValue; 
              return updatedVariations;
          
      }); }
	  
	  const handleChangepPrice = (e, index) => { 
            const newValue = e.target.value; 
            setOldVararations(prevVariations => { 
              const updatedVariations = [...prevVariations]; 
              updatedVariations[index].p_price = newValue; 
              return updatedVariations;
          
      }); }



      
    const handleweight = (e, index) => {
      const newValue = e.target.value; 
      setOldVararations(prevVariations => { 
        const updatedVariations = [...prevVariations]; 
        updatedVariations[index].shippingweight = newValue; 
        return updatedVariations;
      }); }

  const handleheight = (e, index) => {
    const newValue = e.target.value; 
    setOldVararations(prevVariations => { 
      const updatedVariations = [...prevVariations]; 
      updatedVariations[index].height = newValue; 
      return updatedVariations;
    }); }

  const handlebreadth = (e, index) => {
    const newValue = e.target.value; 
    setOldVararations(prevVariations => { 
      const updatedVariations = [...prevVariations]; 
      updatedVariations[index].breadth = newValue; 
      return updatedVariations;
    }); }

  const handlewidth = (e, index) => {
    const newValue = e.target.value; 
    setOldVararations(prevVariations => { 
      const updatedVariations = [...prevVariations]; 
      updatedVariations[index].width = newValue; 
      return updatedVariations;
    }); }
  

  
    const navigate = useNavigate();
  
    if(userID <= 0 ){
      navigate('/login');
   }

   
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};
  
    useEffect(() => {
      const des = oldData?.data?.[0]?.content;
      const cat = oldData?.data?.[0]?.cat;
      const name= oldData?.data?.[0]?.product;
      const code= oldData?.data?.[0]?.product_code;
      const price = oldData?.data?.[0]?.price;
      const file = oldData?.data?.[0]?.file;
      const file1 = oldData?.data?.[0]?.file1;
      const file2 = oldData?.data?.[0]?.file2;
      const file3 = oldData?.data?.[0]?.file3;
      const video = oldData?.data?.[0]?.video;
      const width= oldData?.data?.[0]?.width;
      const height = oldData?.data?.[0]?.height;
      const breadth = oldData?.data?.[0]?.breadth;
      const weight = oldData?.data?.[0]?.weight;
      const stock = oldData?.data?.[0]?.stock;
      const stockType = oldData?.data?.[0]?.messurement;
	  const keyword = oldData?.data?.[0]?.keyword;
	  
  
      setName(name);
      setWidth(width);
      setHeight(height);
      setBreadth(breadth);
      setWeight(weight);
      setPrice(price);
      setFile(file);
      setFile1(file1);
      setFile2(file2);
      setFile3(file3);
      setVideo(video);
      setdes(des);
      setCat(cat);
      setStock(stock);
      setMessure(stockType);
      setCode(code);
	  setKeyword(keyword);
    }, [oldData]);




    

   
    
  
  
  


    const handleCode = (event) => {
      setCode(event.target.value); // Changed setInput to setLink
    };
  
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    }
	
	const handleKeyword = (event) => {
        setKeyword(event.target.value); // Changed setInput to setLink
    };
    
    
   
    const handleDesChange = (event) => {
      setdes(event.target.value);
	  if (typeof window !== 'undefined') {
    ClassicEditor
      .create(document.querySelector('#classic-editor'), {
        // CKEditor configuration here
      })
      .then(editor => {
        editor.model.document.on('change:data', () => {
          const data = editor.getData();
          setdes(data);
        });
      })
      .catch(error => {
        console.error('Error initializing CKEditor:', error);
      });
  }
    }
    const handlecatChange = (event) => {
      setCat(event.target.value);
    }


    const handleStockChange = (event) => {
      setStock(event.target.value);
    }
  
    const handleFileChange = (event) => {
     
      const file = event.target.files[0];
      setFile(file);
    }
  
    const handleFileChange1 = (event) => {
    
      const file1 = event.target.files[0];
      setFile1(file1);
    }
  
    const handleFileChange2 = (event) => {
      
      const file2 = event.target.files[0];
      setFile2(file2);
    }
  
    const handleFileChange3 = (event) => {
    
      const file3 = event.target.files[0];
      setFile3(file3);
    }
  
    const handleVideoChange = (event) => {
     
      const video = event.target.files[0];
      setVideo(video);
    }

    const handleMesChange = (event) => {
      setMessure(event.target.value);
  };

    
  //   const handleweight = (event) => {
  //     setWeight(event.target.value);
  // };

  // const handleheight = (event) => {
  //     setHeight(event.target.value);
  // };


  // const handlebreadth = (event) => {
  //     setBreadth(event.target.value);
  // };

  // const handlewidth = (event) => {
  //     setWidth(event.target.value);
  // };
   const handleUrl = (event) => {
        setVideo(event.target.value); // Changed setInput to setLink
    };



 


  




  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const formData = new FormData();
      formData.append('id', productId);
      formData.append('content', des);
      formData.append('images', file);
      formData.append('image1', file1);
      formData.append('image2', file2);
      formData.append('image3', file3);
      formData.append('name', name);
      formData.append('cat', cat);
      formData.append('price', price);
      formData.append('video', video);
      formData.append('height', height);
      formData.append('width', width);
      formData.append('breadth', breadth);
      formData.append('weight', weight);
      formData.append('stock', stock);
      formData.append('stockType', messure);
      formData.append('code', code);
	  formData.append('keyword', keyword);
      

      //console.log(formData);
     
      
      openModal();
      try {
     
        const response = await axios.post('https://api.arivomkadai.com/editofflineproduct/', formData);
       // console.log(response.data)
       navigate(`/offline`);
      } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
      }
      updateVariation(event);
    }

    const updateVariation =  async (e) => {
      e.preventDefault();
      try {
        const response = await axios.post('https://api.arivomkadai.com/editvarients/', {oldvariations});
       // console.log(response.data)
      } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
      }
        closeModal();
    }

  
    return (
      <>
       


       <div id="onloadModal" >
      <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}}>Product Loading.</p>
      </div>
       </div>

        

       
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside/>
          <div className="layout-page">
            <Navbar/>

          


            <div className="content-wrapper">

          
           



              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Update Product</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <h5 className="card-header">Update Product</h5>
                      <div className="card-body">
                      
                          <div className="row">
                                                    <div className="mb-3 col-md-3">
                                                        <label htmlFor="link" className="form-label">Product Code</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            id="code"
                                                            name="code"
                                                            value={code}
                                                            onChange={handleCode}
                                                        />
                                                    </div>
                                                      <div className="mb-3 col-md-5">
                                                          <label htmlFor="link" className="form-label">Name</label>
                                                          <input
                                                              className="form-control"
                                                              type="text"
                                                              id="name"
                                                              name="name"
                                                              value={name}
                                                              onChange={handleNameChange}
                                                          />
                                                      </div>
  
                                                      <div className="mb-3 col-md-2">
                                                          <label htmlFor="des" className="form-label">Category</label>
                                                         <select
                                                              className="form-select"
                                                              name="cat"
                                                              id="cat"
                                                              value={cat}
                                                              onChange={handlecatChange}
                                                          >
                                                         <option value="">Select Category</option>
														 <option value="Tech">Tech</option>
														 <option value="Home">Home</option>
														 <option value="Kitchen">Kitchen</option>
														 <option value="Kids">Kids</option>
														 <option value="Gifts">Gifts</option>
														 <option value="Personalcare">Personalcare</option>
														 <option value="Health">Health</option>
														 <option value="Automobile">Automobile</option>
														 <option value="Fashions">Fashions</option>
                             <option value="Used">Used</option>
                                                         </select>
                                                      </div>
                           
                            <div className="mb-3 col-md-2">
                              <label htmlFor="lastName" className="form-label">Stock</label>
                              <input
                                className="form-control"
                                type="text"
                                 name="stock"
                                 value={stock}
                                  onChange={handleStockChange}
                              />
                            </div>


                            

  
  
                            <div className="mb-3 col-md-3">
                                                          <label htmlFor="link" className="form-label">Stock Mesurement</label>
                                                          <select
                                                              className="form-select"
                                                              name="messure"
                                                              onChange={handleMesChange}
                                                              value={messure}
                                                          >
                                                        {messure === '' ?( <option>Select Type</option>) : (<option selected value={messure} hidden>{messure}</option>)}
														                             <option value="Counts" >Counts</option>
														                             <option value="Kg">Kg</option>
														                             <option Value="L">L</option>
														
                                                         </select>
                                                      </div>
                                             

                                                     
													
													
													 
                                                     <div className="mb-3 mt-4 col-md-4">
													   <button type="button"  className={selectedVideo === 'video' ? 'btn btn-primary me-2' : 'btn btn-secondary me-2'} onClick={selectVideo}>Video File</button>
													   <button type="button" className={selectedVideo === 'video' ? 'btn btn-secondary me-2' : 'btn btn-primary me-2'} onClick={selectVideo}>YouTube Video</button>
                                                    </div>
                                                    
                                                    
                             {selectedVideo === 'video' ? (     
<div className="mb-3 col-md-4">
                                                          <label htmlFor="des" className="form-label">Video</label>
                                                          <input
                                                              className="form-control"
                                                              type="file"
                                                              name="video"
                                                              id="video"
                                                              accept="mp4"
                                                              onChange={handleVideoChange}
                                                          />
                                                      </div>							 
                           
							):(
													
													<div className="mb-3 col-md-4">
                                                        <label htmlFor="des" className="form-label">Video Url</label>
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            name="url"
                                                            id="url"
															 value={video}
                                                            onChange={handleUrl}
                                                            
                                                        />
                                                    </div>
													)}
													
	                         <div className="mb-3 col-md-3">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="images"
                                id="images"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange}
                              />
                            </div>
                            <div className="mb-3 col-md-3">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="image1"
                                id="image1"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange1}
                              />
                            </div>
                            <div className="mb-3 col-md-3">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="image2"
                                id="image2"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange2}
                              />
                            </div>
                            <div className="mb-3 col-md-3">
                              <label htmlFor="lastName" className="form-label">Image</label>
                              <input
                                className="form-control"
                                type="file"
                                name="image3"
                                id="image3"
                                accept="image/png, image/jpeg, image/jpg, image/pdf"
                                onChange={handleFileChange3}
                              />
                            </div>
                           

                                                      <div className="mb-3 col-md-12">
                                                          <label htmlFor="des" className="form-label">Description</label>
                                                          <textarea
                                                              className="form-control"
                                                              id="classic-editor"
                                                              rows="3"
                                                              name="des"
                                                              onChange={handleDesChange}
                                                              value={des}
                                                          />
                                                      </div>
													  
													  
													  {/* <div className="mb-3 col-md-12">
                                                        <label htmlFor="des" className="form-label">Keywords</label>
                                                        <textarea
                                                            className="form-control"
                                                            type="text"
                                                            name="keyword"
                                                            id="keyword"
                                                            value={keyword}
                                                            onChange={handleKeyword}
                                                        />
                                                    </div> */}
													
                    
     <h5 className="card-header">Product Variations</h5> 
      <div class="card-body">



      {oldvariations && oldvariations.length>0?(
       <>
	   

{oldvariations.map((variation, index) => (
   <div key={index}>
        <h5 className="card-header">Edit Variations</h5>
        <div className="row" align="center">
          <div className="mt-3 col-md-2"><h6>Variation</h6></div>
          <div className="mt-3 col-md-2"><h6>Measurement</h6></div>
          <div className="mt-3 col-md-2"><h6>Selling Price</h6></div>
          <div className="mt-3 col-md-2"><h6>MRP</h6></div>
          <div className="mt-3 col-md-2"><h6>Purchase Price</h6></div>
          <div className="mt-3 col-md-2"><h6>GST % </h6></div>
        </div>

        <div key={index} className="row">
    <div className="mt-3 col-md-2">
      
      <input    className="form-control" placeholder='Variation'
        type="text" key={index} value={variation.value} onChange={(e) => handleChangeValue(e, index)} />
    </div>
    <div className="mt-3 col-md-2">
    
      <select
        className="form-control"
        type="text"
        
        value={variation.weights}
        onChange={(e) => handleChangeWeight(e, index)}
      >
         <option value={variation.weights}>{variation.weights}</option>
                                                         <option value="g">g</option>
														 <option value="kg">kg</option>
														 <option value="l">l</option>
                                                         <option value="ml">ml</option>
                                                         <option value="counts">counts</option>
                                                   </select>
    </div>
    <div className="mt-3 col-md-2">
    
      <input
        className="form-control"
        type="text"
        value={variation.price}
        onChange={(e) => handleChangePrice(e, index)}
      />
	   </div>
    <div className="mt-3 col-md-2">
	   <input
        className="form-control"
        type="text"
        value={variation.mrp}
        onChange={(e) => handleChangemrp(e, index)}
      /> </div>
    <div className="mt-3 col-md-2">
	   <input
        className="form-control"
        type="text"
        value={variation.p_price}
        onChange={(e) => handleChangepPrice(e, index)}
      />
    </div>
    <div className="mt-3 col-md-2">
	   <input
        className="form-control"
        type="text"
        value={variation.gst}
        onChange={(e) => handleChangeGst(e, index)}
      />
    </div>

         
        
        </div>


         {/* Header for Shipping Details */}
         <h5 className="card-header">Edit Shipping Details</h5>

{/* Shipping Details Inputs */}
<div className="row" align="center">
  <div className="mt-3 col-md-2"><h6>Breadth in cm</h6></div>
  <div className="mt-3 col-md-2"><h6>Width in cm</h6></div>
  <div className="mt-3 col-md-2"><h6>Height in cm</h6></div>
  <div className="mt-3 col-md-2"><h6>Weight in Kg</h6></div>
</div>
<div className="row">
  <div className="mt-3 col-md-2">
    <input
      className="form-control"
      type="text"
      name="breadth"
      value={variation.breadth}
      onChange={(e) => handlebreadth(e, index)}
    />
  </div>
  <div className="mt-3 col-md-2">
    <input
      className="form-control"
      type="text"
      name="width"
      value={variation.width}
      onChange={(e) => handlewidth(e, index)}
    />
  </div>
  <div className="mt-3 col-md-2">
    <input
      className="form-control"
      type="text"
      name="height"
      value={variation.height}
      onChange={(e) => handleheight(e, index)}
    />
  </div>
  <div className="mt-3 col-md-2">
    <input
      className="form-control"
      type="text"
      name="shippingweight"
      value={variation.shippingweight}
      onChange={(e) => handleweight(e, index)}
    />
  </div>

  <div className="mt-3 col-md-2">
          <button class="btn btn-danger btn-sm"  onClick={()=>handleChangeDelete(index)}><i class="bx bx-trash bx-xs me-1"></i></button>
          </div>
</div>



        </div>
      ))}
    
    </>
         ):(<></>)}
          <div className="row">
       <div className="mt-3 col-md-4">
          <button class="btn btn-primary btn-sm"  onClick={handleChangeAdd}><i class="bx bx-plus bx-xs me-1"></i>Add New Variation</button>
        </div>
        </div>
 

    
     </div></div>

                          <div className="mt-2">
                            <button onClick={handleSubmit} className="btn btn-primary me-2">Save changes</button>
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
            <div className="content-backdrop fade"></div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      </>
  )
}
export default Editofflineproduct;
