import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import '../App.css';
ReactSession.setStoreType("localStorage");


function Editstock() {
    const userID = ReactSession.get('userId');
    const urlParams = new URLSearchParams(window.location.search);
    const productId = urlParams.get('id');
    //console.log(productId); 
    const [oldData, setOldData] = useState({});
    const [error, setError] = useState('');
    const [stock, setStock] = useState("");
    const [messure, setMessure] = useState("");

    useEffect(() => {
      
        const stock = oldData?.data?.[0]?.stock;
        const stockType = oldData?.data?.[0]?.messurement;
    
        
        setStock(stock);
        setMessure(stockType);
    
      }, [oldData]);

    useEffect(() => {
        const fetchOldData = async () => {
          try {
            // Make API call to fetch old data
            const response = await axios.get(`https://api.arivomkadai.com/editofflineproduct/?id=${productId}`);
            const oldData = response.data;
            setOldData(oldData); // Set oldData state
           // console.log(oldData);
          } catch (error) {
            console.error('Error fetching old data:', error);
          }
        };
    
        // Call the fetchOldData function
        fetchOldData();
      }, []);

      
  



  

  
    const navigate = useNavigate();
  
    if(userID <= 0 ){
      navigate('/login');
   }

   
 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};
  
    useEffect(() => {
    
      const stock = oldData?.data?.[0]?.stock;
      const stockType = oldData?.data?.[0]?.messurement;
  
     
      setStock(stock);
      setMessure(stockType);
  
    }, [oldData]);


    const handleStockChange = (event) => {
      setStock(event.target.value);
    }
  
    

    const handleMesChange = (event) => {
      setMessure(event.target.value);
  };

    
  
    const handleSubmit = async (event) => {
      event.preventDefault();
  
      const formData = new FormData();
      
      formData.append('stock', stock);
      formData.append('stockType', messure);
     
      

      //console.log(formData);
     
      
      openModal();
      try {
     
        const response = await axios.post('https://api.arivomkadai.com/editofflineproduct/', formData);
       //console.log(response.data)
      
	   navigate('/stockmaintance');
      } catch (error) {
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
      }
      
    }

   
  
    return (
      <>
       


       <div id="onloadModal" >
      <div class="modal-content"  align='center'>
          <p style={{padding:'20px'}}>Product Loading.</p>
      </div>
       </div>

        

       
      <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
          <Aside/>
          <div className="layout-page">
            <Navbar/>

          


            <div className="content-wrapper">

          
           



              <div className="container-xxl flex-grow-1 container-p-y">
                <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Update Product</h4>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card mb-4">
                      <h5 className="card-header">Update Product</h5>
                      <div className="card-body">
                      
                          <div className="row">
                                                   
  
                                                     
                           
                            <div className="mb-3 col-md-2">
                              <label htmlFor="lastName" className="form-label">Stock</label>
                              <input
                                className="form-control"
                                type="text"
                                 name="stock"
                                 value={stock}
                                  onChange={handleStockChange}
                              />
                            </div>


                            

  
  
                            <div className="mb-3 col-md-3">
                                                          <label htmlFor="link" className="form-label">Stock Messurement</label>
                                                          <select
                                                              className="form-select"
                                                              name="messure"
                                                              onChange={handleMesChange}
                                                              value={messure}
                                                          >
                                                        {messure === '' ?( <option>Select Type</option>) : (<option selected value={messure} hidden>{messure}</option>)}
														                             <option value="Counts" >Counts</option>
														                             <option value="Kg">Kg</option>
														                             <option Value="L">L</option>
														
                                                         </select>
                                                      </div>
                                             

                                                     
                    
     </div>

                          <div className="mt-2">
                            <button onClick={handleSubmit} className="btn btn-primary me-2">Save changes</button>
                          </div>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer/>
            <div className="content-backdrop fade"></div>
          </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
      </div>

      </>
  )
}
export default Editstock;
