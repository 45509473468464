import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");

 function Preorder_details() {
    const userID = ReactSession.get('userId');
    const [order, setOrder]=useState({});
    const [status, setStatus]=useState('');
    const [process, setProcess] = useState([]);
    const queryParameters = new URLSearchParams(window.location.search)
    const orderId = queryParameters.get("id");
    //console.log(orderId);
    const navigate = useNavigate();

    if(userID <= 0 ){
        navigate('/login');
     }


     const updatestatus = async () => {
        const formData = {
            id:orderId,
            status: status
        }
        //console.log(formData);
        try {
            // Make API call to verify OTP
            const response = await axios.post('https://api.arivomkadai.com/preorder_status/', formData);
             //console.log(response.data);
        } catch (error) {
            console.error('Status Error:', error);
        }
    };
 
  const fetchData = async () => {
   
    // console.log("user:",formData);
   try {
    const formData = {id:orderId};
     const response = await axios.post('https://api.arivomkadai.com/proordersucess/' , formData);
     setProcess(response.data);
    //console.log("orders:", response.data);
   } catch (error) {
     
     console.error("Error fetching data:", error);
   }
 };
 useEffect(() => {
 fetchData();
 }, []);

 const fetchDatas = async () => {
   
    // console.log("user:",formData);
   try {
     const response = await axios.get('https://api.arivomkadai.com/preorder_orders/');
     setOrder(response.data);
    // console.log("orders:", response.data);
   } catch (error) {
     
     console.error("Error fetching data:", error);
   }
 };
 useEffect(() => {
 fetchDatas();
 }, []);

 const order_ids = order && order.data && order.data.filter(item => item.order_id === orderId); // Filter product data based on the category ID

 //console.log("Filtered orders:", order_ids);

   const Stcourier = async ()=>{
	      const formData = {
            order_id:orderId,
            order_form: 'preorder'
        }
       // console.log(formData);
        try {
            // Make API call to verify OTP
            const response = await axios.post('https://api.arivomkadai.com/stcourier/', formData);
            alert(response.data.id);
        } catch (error) {
            console.error('Status Error:', error);
        }
    }

  return (
    <div className="layout-wrapper layout-content-navbar">
    <div className="layout-container">
      <Aside />
      <div className="layout-page">
        <Navbar />
        <div className="content-wrapper">
    
        <div class="container-xxl flex-grow-1 container-p-y">
        <div class="row">
        <div class="col-lg-8 mb-4 order-0">
                
    <div class="card mb-4">
      <div class="card-header d-flex justify-content-between align-items-center">
        <h5 class="card-title m-0">Order Details</h5>
        <h6 class="m-0"><a href=" javascript:void(0)">Edit</a></h6>
      </div>
      <div class="card-datatable table-responsive">
        <table class="datatables-order-details table">
          <thead>
            <tr>
              <th></th>
              <th class="w-50">products</th>
              <th class="w-25">price</th>
              <th class="w-25">qty</th>
              <th>total</th>
            </tr>
          </thead>
          <tbody>
          {process && process.data && process.data.orderdetails && process.data.orderdetails.map(product => (
        
        <tr class="odd"><td class="  control" tabindex="0" style={{display: 'none'}} key={product.id}>
            </td >
                <td class="sorting_1">
                    <div class="d-flex justify-content-start align-items-center text-nowrap">
                        <div class="avatar-wrapper"><div class="avatar me-2">
                            <img src={`https://api.arivomkadai.com/product_data/${product.img}`} alt={product.product} class="rounded-2"/>
                                </div>
                                </div>
                               
                                    </div>
                                    </td>
                                    <td><span>{product.product}</span><br></br><p class="text-muted">{product.code}<br></br>{product.varient}</p></td>
                                    <td><span>₹{product.price}</span></td>
                                    <td><span class="text-body">{product.quantity}</span></td>
                                    <td><h6 class="mb-0">₹{product.subtotal}</h6></td>
                                    </tr>
            ))}
          </tbody>
        </table>
        <div class="d-flex justify-content-end align-items-center m-3 mb-2 p-1">
          <div class="order-calculations">
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Subtotal:</span>
              <span class="text-heading">₹ {process && process.data && process.data.total}.00</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="w-px-100">Discount:</span>
              <span class="text-heading mb-0">₹{process && process.data && process.data.offer || 0}.00</span>
            </div>
            <div class="d-flex justify-content-between">
              <h6 class="w-px-100 mb-0">Total:</h6>
              <h6 class="mb-0">₹{process && process.data && process.data.grandtotal || 0}.00</h6>
            </div>
          </div>
        </div>
      </div>
    </div>
   
              </div>
              
              <div class="col-md-6 col-lg-4 col-xl-4 order-0 mb-4">
              {order_ids && Array.isArray(order_ids) && order_ids.map(customer => (
                <div class="card pb-4">
                
                  <div class="card-header d-flex align-items-center justify-content-between pb-0">
                 
                    <div class="card-title mb-0">
                      <h5 class="m-0 me-2 mb-2">Arivomkadai Customers</h5>
                      <span>Total Orders</span>
                    </div>
                  
                    <div class="avatar flex-shrink-0">
                            <img src="../assets/img/icons/unicons/cc-primary.png" alt="Credit Card" class="rounded" />
                          </div>
                    
                  </div>
                  <div class="card-body">
        <div class="d-flex justify-content-start align-items-center mb-4">
          <div class="avatar me-2">
            <img src={`https://api.arivomkadai.com/customer_data/${customer.customerData[0].profile}`} alt="Avatar" class="rounded-circle"/>
          </div>
          <div class="d-flex flex-column">
            <a href="app-user-view-account.html" class="text-body text-nowrap">
              <h6 class="mb-0">{customer.customerData[0].name}</h6>
              <small class="text-muted">Order ID: {customer.order_id}</small>
            </a></div>
        </div>
        <div class="d-flex justify-content-start align-items-center mb-4">
          <span class="avatar rounded-circle bg-label-success me-2 d-flex align-items-center justify-content-center"><i class="bx bx-cart-alt bx-sm lh-sm"></i></span>
          <h6 class="text-body text-nowrap mb-0">{customer.count} Items</h6>
        </div>
        <div class="d-flex justify-content-between">
          <h6>Contact Info</h6>
           </div>
        <p class=" mb-1">Address: {customer.building}  {customer.street}</p>
        <p class=" mb-1">Landmark: {customer.landmark}</p>
		 <p class=" mb-1">City: {customer.city}</p>
		  <p class=" mb-1">State: {customer.state}</p>
		   <p class=" mb-1">Pincode: {customer.buiulding}  {customer.Pincode}</p>
        <p class=" mb-1">Email: {customer.email}</p>
        <p class=" mb-0">Mobile: {customer.number}</p>

        <h5 className="card-header">Status</h5>
                                        <div className="card-body">
                                                <div className="row">
                                                    <div className="mb-3 col-md-3 col-xl-12 col-lg-12">


                                                        <label htmlFor="link" className="form-label">Order Status</label>
																		                <select class="form-control" name="status" value={status} onChange={(e) => setStatus(e.target.value)}>
																			                <option selected hidden >{customer.status}</option> 
																			                <option value="Placed">Confirm</option> 
																			                <option value="Shipped">Shipped</option> 
																			                <option value="Delivered">Delivered</option> 
																			                <option value="Cancelled">Cancelled</option> 
																			            </select>
                                                    </div>
													
                                                </div>
                                                <div className="mt-2">
                                                    <button onClick={updatestatus} className="btn btn-primary me-2">Update</button>
													<a href={`invoice?id=${customer.order_id}`} class="btn btn-warning">Invoice</a>
													<button  class="btn btn-info mt-2" onclick={Stcourier}>Create St Courier Shipping</button>
                                                </div>
												
												
												
                                        </div>



      </div>
        
                </div>
            ))}
              </div>
              </div>


              </div>




          
          <Footer />
          <div className="content-backdrop fade"></div>
        </div>
      </div>
    </div>
    <div className="layout-overlay layout-menu-toggle"></div>
  </div>
  )
}
export default Preorder_details;
