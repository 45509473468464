import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer'; 
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css'; 
import { ReactSession } from 'react-client-session'; 
import * as XLSX from 'xlsx';
import ContentEditable from 'react-contenteditable';


function Reportoffline() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); 
    const [rows, setRows] = useState(15);
    const [button, setButton] = useState(false); 
    const [datas, setData] = useState([]);

    console.log('First', first, 'Rows', rows);
 
    const navigate = useNavigate();
    if(userID <= 0 ){
      navigate('/login');
    }


  const fetchData = async () => {
    try {
      const response = await axios.get('https://api.arivomkadai.com/offlineproduct/');
	    setData(response.data);
      //console.log(paid);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    fetchData();
  }, []);


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15);
  };

  const handleDownloadExcel = () => {
    setFirst(0);
    setRows(filteredDatas.length);
    setButton(true);
    setTimeout(() => {
      downloadExcel();
    }, 1000);
  };

  const downloadExcel = () => {
  
    const input = document.getElementById('order'); 
    const table = input.querySelector('table');
    if (!table) {
      console.error('No table found inside the specified element');
      return;
    }
  
    const ws = XLSX.utils.table_to_sheet(table);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `stock_report.xlsx`);

    setFirst(0);
    setRows(15);
    setButton(false);
  };

  const [filterId, setFilterId] = useState('');
  const [filterName, setFilterName] = useState('');
  

  const filteredDatas = datas.data && datas.data.filter(item => {
    
    return (
      (filterId ? item.product_code.includes(filterId) : true) && 
      (filterName ? item.content.toLowerCase().includes(filterName.toLowerCase()) : true) 
     
    );
  });

  
  const [isReadMoreMap, setIsReadMoreMap] = useState({});

  const toggleDescription = (p_id) => {
      setIsReadMoreMap(prevState => ({
          ...prevState,
          [p_id]: !prevState[p_id]
      }));
  };

  const openModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.add('show');
        modal.style.display = 'block';
        document.body.classList.add('modal-open');
    }
  };
  
  const closeModal = () => {
    const modal = document.getElementById('onloadModal');
    if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
    }
  };
  
  useEffect(() => {
    if(filteredDatas && filteredDatas.length === 0 || !filteredDatas){
      openModal();
    }else{
      closeModal();
    }
  },[filteredDatas]);

  return (
    <>
  <div id="onloadModal">
        <div class="modal-content" align='center'>
            <p style={{padding: '20px'}}>Loading data please wait...</p>
        </div>
    </div>
    <div className="layout-wrapper layout-content-navbar">
        <div className="layout-container">
            <Aside/>
            <div className="layout-page">
                <Navbar/>
                <div className="content-wrapper">
                    <div className="container-xxl flex-grow-1 container-p-y">
                        <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Arivomkadai Report</h4>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="card mb-4">
                                    <h5 className="card-header">Arivomkadai Report  
                                      <div  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                        <button onClick={handleDownloadExcel}  className={`btn ${button === false ? 'btn-primary' : 'btn-secondary'}`} > <i class="fa fa-download me-2"></i> {button === false ? 'Download' : 'Downloading'}</button>
                                      </div>
                                    </h5>
                                    <div className="card-body">
                                                        <div className="row ml-1 mr-1" >
                                                            
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Filter by ID"
                                                                onChange={(e) => setFilterId(e.target.value)}
                                                              />
                                                            </div>
                                                            <div className="col-6 col-md-3 col-lg-3 col-xl-3 col-xxl-3 mt-2">
                                                              <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Filter by Name"
                                                                onChange={(e) => setFilterName(e.target.value)}
                                                              />
                                                            </div>
                                                            
                                                        </div>

                                                        <div className="table-responsive text-nowrap mt-2" id="order">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th>S.No</th>
                                                                         <th>Item Code</th>
                                                                         <th>Item</th>
                                                                         <th>Item Description</th>
                                                                         <th>stock</th>
                                                                         
                                                                         </tr>
                                                                </thead>
                                                                <tbody className="table-border-bottom-0">
                                                                    
                                                                {filteredDatas && filteredDatas.slice(first, first + rows).map((item, index)=> (
  <tr  key={index}>
     <td>{index+first+1}</td>
     <td>{item.product_code}</td>
     <td>{item.product}</td>
     <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                          {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 60)}`} />)} 
                          <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                  {isReadMoreMap[item.p_id] ? ' Show Less' : ' ...Read More'}
                          </a>
                      </td>
    <td>{item.stock+' '+item.stockType}</td>
   


    
  </tr>
 ))}
                                                                </tbody>
                                                            </table>
                                                            <Paginator first={first} rows={rows} totalRecords={filteredDatas ? filteredDatas.length : 0} onPageChange={onPageChange} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                    <div className="content-backdrop fade"></div>
                </div>
            </div>
        </div>
        <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  )
}

export default Reportoffline;
