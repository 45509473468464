import React from 'react'

function footer() {
  return (
    <footer class="content-footer footer bg-footer-theme">
    <div class="container-xxl d-flex flex-wrap justify-content-between py-2 flex-md-row flex-column">
      <div class="mb-2 mb-md-0">
        © 2024 ArivomKadai All Rights Reserved, Websolutions By

        <a href="https://sninfoserv.com" target="_blank"  class="footer-link fw-bolder ml-2">SN Infoserv</a>
        </div>
     
    </div>
  </footer>
  )
}

export default footer