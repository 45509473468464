import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';
ReactSession.setStoreType("localStorage");




 function Dashboard() {
  const userID = ReactSession.get('userId');
  //console.log("user:", userID);
  const navigate = useNavigate();
   const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [order, setOrder]=useState({});
  const [orders, setOrders]=useState({});
  if(userID <= 0 ){
    navigate('/login');
 }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.arivomkadai.com/product/');
        setData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://api.arivomkadai.com/offlineproduct/');
        setDatas(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);


  const fetchData = async () => {
   
   // console.log("user:",formData);
  try {
    const response = await axios.get('https://api.arivomkadai.com/offlineorders/');
    setOrder(response.data);
//    console.log(response.data);
  } catch (error) {
    
    console.error("Error fetching data:", error);
  }
};
useEffect(() => {
fetchData();
}, []);

const fetchDatas = async () => {
  try {
    const response = await axios.post('https://api.arivomkadai.com/onlineorders/');
    setOrders(response.data);
    //console.log("orders:", response.data);
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

useEffect(() => {
  fetchDatas();
}, []);

 

  return (
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Aside />
        <div className="layout-page">
          <Navbar />
          <div className="content-wrapper">
      
          <div class="container-xxl flex-grow-1 container-p-y">
          <div class="row">
          <div class="col-lg-8 mb-4 order-0">
                  <div class="card">
                    <div class="d-flex align-items-end row">
                      <div class="col-sm-7">
                        <div class="card-body">
                          <h5 class="card-title text-primary p-2">Products</h5>
                          <p class="mb-4">
                            <span class="fw-bold">Arivomkadai:</span> Bridging the Gap Between Online and Arivomkadai Excellence products.
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-5 text-center text-sm-left">
                        <div class="card-body pb-0 px-0 px-md-4">
                          <img
                            src="../assets/img/illustrations/man-with-laptop-light.png"
                            height="140"
                            alt="View Badge User"
                            data-app-dark-img="illustrations/man-with-laptop-dark.png"
                            data-app-light-img="illustrations/man-with-laptop-light.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 order-1">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-6 mb-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="card-title d-flex align-items-start justify-content-between">
                            <div class="avatar flex-shrink-0">
                              <img
                                src="../assets/img/icons/unicons/chart-success.png"
                                alt="chart success"
                                class="rounded"
                              />
                            </div>
                            <div class="dropdown">
                            <div class="dropdown">
                            <h5 class="card-title text-primary">Online</h5>
                            
                              
                            </div>
                              
                            </div>
                          </div>
                          <h3 class="card-title mb-2"> {data && data.count}</h3>
                          <a href="/online" class="btn btn-sm btn-outline-primary">View products</a>
                          </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-6 mb-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="card-title d-flex align-items-start justify-content-between">
                            <div class="avatar flex-shrink-0">
                              <img
                                src="../assets/img/icons/unicons/wallet-info.png"
                                alt="Credit Card"
                                class="rounded"
                              />
                            </div>
                            <div class="dropdown">
                            <h5 class="card-title text-primary p-1">Arivom</h5>
                            
                              
                            </div>
                          </div>
                          
                          <h3 class="card-title mb-2">{datas && datas.count}</h3>
                           <a href="/offline" class="btn btn-sm btn-outline-primary">View products</a>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>


                <div class="row">
                
                <div class="col-md-6 col-lg-6 col-xl-6 order-0 mb-4">
                  <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between pb-0">
                      <div class="card-title mb-0">
                        <h5 class="m-0 me-2">Online Orders</h5>
                        <span>Total Orders</span>
                      </div>
                      <div class="avatar flex-shrink-0">
                              <img src="../assets/img/icons/unicons/cc-primary.png" alt="Credit Card" class="rounded" />
                            </div>
                      
                    </div>
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column align-items-center gap-1">
                          <h2 class="mb-2">{orders && orders.count}</h2>
                          </div>
                          <a href="/onlineorder" class="btn btn-sm btn-outline-primary mr-5" >View Orders</a>
                      
                      </div>
                    </div>
                  </div>
                </div>


                <div class="col-md-6 col-lg-6 col-xl-6 order-0 mb-4">
                  <div class="card">
                    <div class="card-header d-flex align-items-center justify-content-between pb-0">
                      <div class="card-title mb-0">
                        <h5 class="m-0 me-2">Arivomkadai Orders</h5>
                        <span>Total Orders</span>
                      </div>
                      <div class="avatar flex-shrink-0">
                              <img src="../assets/img/icons/unicons/cc-primary.png" alt="Credit Card" class="rounded" />
                            </div>
                      
                    </div>
                    <div class="card-body">
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="d-flex flex-column align-items-center gap-1">
                          <h2 class="mb-2"> {order && order.count}</h2>
                        </div>
                        <a href="/offlineorder" class="btn btn-sm btn-outline-primary mr-5" >View Orders</a>
                      </div>
                    </div>
                  </div>
                </div>

                </div>

                </div>

              

                <div className="container-xxl flex-grow-1 container-p-y ">
              <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span> Products</h4>
              <ul>
         
             <div className="card">
            <h5 className="card-header">Arivomkadai Orders</h5>
           
            <div className="table-responsive text-nowrap mt-2">
              <table className="table table-hover">
                <thead>
                  <tr>
                    <th>customer</th>
                    <th>order_id</th>
                    <th>Date</th>
                    <th>status</th>
                    <th>items</th>
                    <th>Amount</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                  {order && order.data && order.data.slice(-5).map(item => (
                    <tr key={item.order_id}>
                      <td>


                        
                        {item.profile ? (
                          <img src={`https://api.arivomkadai.com/customer_data/${item.profile}`} alt={item.name} className="w-px-40 h-px-40 rounded-circle" />
                        ) : (
                          <img  src={'https://api.arivomkadai.com/customer_data/no_image.webp'}  alt="No Image" className="w-px-40 h-px-40 rounded-circle" />
                        )}
                         <span class="fw-medium ml-1">{item.name}</span>
                      </td>
                      <td>{item.order_id}</td>
                      <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.date}</span></td>
                      <td><span className="badge bg-label-primary me-1 text-wrap" style={{ display: 'inline-block', lineHeight: 1.5 }}>{item.status}</span></td>
                      <td>{item.count}</td>
                      <td>₹ {item.grand_total}</td>
                     <td><a href={`order_details?id=${item.order_id}`} class="btn btn-sm btn-outline-primary">View</a>
                         </td>
                        
                    </tr>
                  ))}
                </tbody>
              </table>
               </div>
          </div>
        </ul>
            </div>


            
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
  );
}
export default Dashboard;