import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import { ReactSession } from 'react-client-session';
import ContentEditable from 'react-contenteditable';
ReactSession.setStoreType("localStorage");


function Product() {
  const userID = ReactSession.get('userId');
  const [data, setData] = useState([]);
  const [datas, setDatas] = useState([]);
  const [first, setFirst] = useState(0); // Index of the first row on the current page
  const [rows, setRows] = useState(15); // Display only one row per page
  const [isOnlineSelected, setIsOnlineSelected] = useState(true);
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState(datas.data);
  const [searchQuerys, setSearchQuerys] = useState('');
  const [filteredDatas, setFilteredDatas] = useState(data.data);
  const [scrollcount, setScrollcount] = useState(0);
  const [scrollcounts, setScrollcounts] = useState(0);
  

  // Function to handle search input change
  const handleSearchInputChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Filter datas.data based on search query
    const filtered = datas.data.filter(item =>
      item.product.toLowerCase().includes(query.toLowerCase()) ||
      item.category.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredData(filtered);
  };

  const handleSearchInputChanges = (event) => {
    const query = event.target.value;
    setSearchQuerys(query);

    // Filter datas.data based on search query
    const filtered = data.data.filter(item =>
      item.product.toLowerCase().includes(query.toLowerCase()) ||
      item.category.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredDatas(filtered);
  };

  

  if(userID <= 0 ){
    navigate('/login');
 }


 
 const handleChange = async(productId, visibility) => {
  let visible = null;
  if(visibility === '1'){
   visible = '0';
  }else{
   visible = '1';
  }
 const response = await axios.put('https://api.arivomkadai.com/deleteproduct/', { action:'visible', id: productId, value:visible });
 if(response.data.status === 'success'){
   fetchData();
}
};

const toggleCheckbox = async(productId, scrollVal) => {
 let scroll = null;
 if(scrollVal === '1'){
  if(scrollcount >= 25){
 scroll = '0';
}else{
  alert("Check  Atleast 25 products");
  scroll = '1';
}
}else{
 scroll = '1';
}
const response = await axios.put('https://api.arivomkadai.com/deleteproduct/', { action:'scroll', id: productId, value:scroll });
if(response.data.status === 'success'){
   fetchData();
}
};



 
const handleChanges = async(productId, visibility) => {
  let visible = null;
  if(visibility === '1'){
   visible = '0';
  }else{
   visible = '1';
  }
 const response = await axios.put('https://api.arivomkadai.com/deleteofflineproduct/', { action:'visible', id: productId, value:visible });
 if(response.data.status === 'success'){
   fetchDatas();
}
};

const toggleCheckboxs = async(productId, scrollVal) => {
 let scroll = null;
 if(scrollVal === '1'){
  if(scrollcounts >= 25){
 scroll = '0';
}else{
  alert("Check  Atleast 25 products");
  scroll = '1';
}
}else{
 scroll = '1';
}
const response = await axios.put('https://api.arivomkadai.com/deleteofflineproduc/', { action:'scroll', id: productId, value:scroll });
if(response.data.status === 'success'){
   fetchDatas();
}
};


 const openModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.add('show');
      modal.style.display = 'block';
      document.body.classList.add('modal-open');
  }
};

const closeModal = () => {
  const modal = document.getElementById('onloadModal');
  if (modal) {
      modal.classList.remove('show');
      modal.style.display = 'none';
      document.body.classList.remove('modal-open');
  }
};

const fetchData = async () => {
  try {
    const response = await axios.get('https://api.arivomkadai.com/product/');
    setData(response.data);
    const count =(response.data.data.filter(item =>item.scroll ==='1')).length;
    // console.log(count);
     setScrollcount(count);
  } catch (error) {
    console.error('Error fetching data:', error);
  }
};

  useEffect(() => {
   

    fetchData();
  }, []);


  const fetchDatas = async () => {
    try {
      const response = await axios.get('https://api.arivomkadai.com/offlineproduct/');
      setDatas(response.data);
      const count =(response.data.data.filter(item =>item.scroll ==='1')).length;
    // console.log(count);
     setScrollcounts(count);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
  

    fetchDatas();
  }, []);

  const removeProduct = async (productId) => {
    openModal();
    //console.log('Deleting product with ID:', productId); // Log the product ID
    try {
      const response = await axios.post('https://api.arivomkadai.com/deleteproduct/', { id: productId });
     // console.log("Response data:", response.data);
      fetchData();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
    closeModal();
  };

  const removeProducts = async (productId) => {
    openModal();
   // console.log('Deleting product with ID:', productId); // Log the product ID
    try {
      const response = await axios.post('https://api.arivomkadai.com/deleteofflineproduct/', { id: productId });
     // console.log("Response data:", response.data);
      fetchDatas();
    } catch (error) {
      console.error('Error deleting product:', error);
    }
    closeModal();
  };


  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };


  const [isReadMoreMap, setIsReadMoreMap] = useState({});

  const toggleDescription = (p_id) => {
      setIsReadMoreMap(prevState => ({
          ...prevState,
          [p_id]: !prevState[p_id]
      }));
  };


    


  return (
    <>
    <div id="onloadModal" >
    <div class="modal-content"  align='center'>
        <p style={{padding:'20px'}}>Product Loading.</p>
    </div>
     </div>
    <div className="layout-wrapper layout-content-navbar">
      <div className="layout-container">
        <Aside />
        <div className="layout-page">
          <Navbar />
          <div className="content-wrapper">

         
      
          <div class="container-xxl flex-grow-1 container-p-y">
          <div class="row">
          <div class="col-lg-8 mb-4 order-0">
                  <div class="card">
                    <div class="d-flex align-items-end row">
                      <div class="col-sm-7">
                        <div class="card-body">
                          <h5 class="card-title text-primary p-2">Products</h5>
                          <p class="mb-4">
                            <span class="fw-bold">Arivomkadai:</span> Bridging the Gap Between Online and Arivomkadai Excellence products.
                          </p>
                        </div>
                      </div>
                      <div class="col-sm-5 text-center text-sm-left">
                        <div class="card-body pb-0 px-0 px-md-4">
                          <img
                            src="../assets/img/illustrations/man-with-laptop-light.png"
                            height="140"
                            alt="View Badge User"
                            data-app-dark-img="illustrations/man-with-laptop-dark.png"
                            data-app-light-img="illustrations/man-with-laptop-light.png"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-4 order-1">
                  <div class="row">
                    <div class="col-lg-6 col-md-12 col-6 mb-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="card-title d-flex align-items-start justify-content-between">
                            <div class="avatar flex-shrink-0">
                              <img
                                src="../assets/img/icons/unicons/chart-success.png"
                                alt="chart success"
                                class="rounded"
                              />
                            </div>
                            <div class="dropdown">
                            <div class="dropdown">
                            <h5 class="card-title text-primary p-1">online</h5>
                            
                              
                            </div>
                              
                            </div>
                          </div>
                          <h3 class="card-title mb-2"> {data && data.count}</h3>
                          <button class="btn btn-sm btn-outline-primary" onClick={() => setIsOnlineSelected(true)}>View products</button>
                          </div>
                      </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-6 mb-4">
                      <div class="card">
                        <div class="card-body">
                          <div class="card-title d-flex align-items-start justify-content-between">
                            <div class="avatar flex-shrink-0">
                              <img
                                src="../assets/img/icons/unicons/wallet-info.png"
                                alt="Credit Card"
                                class="rounded"
                              />
                            </div>
                            <div class="dropdown">
                            <h5 class="card-title text-primary p-1">Arivom</h5>
                            
                              
                            </div>
                          </div>
                          
                          <h3 class="card-title mb-2">{datas && datas.count}</h3>
                          <button class="btn btn-sm btn-outline-primary" onClick={() => setIsOnlineSelected(false)}>View products</button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
                </div>
                </div>




            <div className="container-xxl flex-grow-1 container-p-y">
              <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Products</h4>
              <ul>
          {isOnlineSelected
            ?  <div className="card">
            <h5 className="card-header">Online products</h5>
            <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by product name or category..."
              value={searchQuerys}
              onChange={handleSearchInputChanges}
            />
          </div>
            <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
              <a href="/addproduct" className="btn btn-primary">
                <span className="tf-icons bx bx-plus"></span>&nbsp; Add Product
              </a>
            </div>
            <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover" width="100%">
                      <thead>
                        <tr>
                        <th width="5%">slider</th>
                          <th width="5%">Image</th>
						            <th width="5%">Product</th>
                          <th width="5%">Link</th>
                          <th >Content</th>
                          <th width="5%">visibility</th>
                          <th width="5%">More</th>
                        </tr>
                      </thead>
                <tbody className="table-border-bottom-0">
                {searchQuerys && filteredDatas.length > 0 ? (
            <>  {filteredDatas.map(item => (
              <tr key={item.p_id}>
                            <td>
                            <td>
                  <input type="checkbox" checked={item.scroll === '0' ? false : true} onChange={() => toggleCheckbox(item.p_id, item.scroll)} />
              </td>
  
  
                              
                              {item.image ? (
                                <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
                              ) : (
                                <img  src={'https://api.arivomkadai.com/product_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
                              )}
                            </td>
                            <td>{item.product_code}<br></br>{item.category.slice(0, 20)}<br></br> {item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}<br /></td>
                            <td>{item.link}</td>
                            <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                                {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 20)}`} />)} 
                                <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                        {isReadMoreMap[item.p_id] ? 'Show Less' : ' ...Read More'}
                                </a>
                            </td>
                            <td>
    <span className="text-truncate">
        <label className="switch switch-primary switch-sm">
        <input
                type="checkbox"
                className="switch-input"
                onChange={() => handleChange(item.p_id, item.visibility)}
            />
            <span
                className={item.visibility === '1' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}
               
            ></span>
            <span className="switch-label"></span>
            <span className={ item.visibility === '1' ?"switch-handle-L" :"switch-handle-R"} ></span>
        </label>
        <span className="d-none">Out_of_Stock</span>
    </span>
</td>
                            <td>
                              <div className="dropdown">
                                <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                  <i className="bx bx-dots-vertical-rounded"></i>
                                </button>
                                <div className="dropdown-menu">
                                  <a className="dropdown-item" href={`editproduct?id=${item.p_id}`}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                                  <a className="dropdown-item"  onClick={() => removeProduct(item.p_id)}><i className="bx bx-trash me-1"></i> Delete</a>
                                </div>
                              </div>
                            </td>
                          </tr>
                           ))}
                           </>
                         ) : searchQuery && filteredDatas.length === 0 ? (
                          <tr >
                                       <td>No products found </td>
                                     </tr>
                       ) : (<>
                  {data && data.data && data.data.slice(first, first + rows).map(item => (
                    <tr key={item.p_id}>
                        <td>
                  <input type="checkbox" checked={item.scroll === '0' ? false : true} onChange={() => toggleCheckbox(item.p_id, item.scroll)} />
              </td>
                      <td>
                        {item.image ? (
                          <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
                        ) : (
                          <img  src={'https://api.arivomkadai.com/product_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
                        )}
                      </td>
					            <td>{item.product_code}<br></br>{item.category}<br></br>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</td>
                      <td>{item.link}</td>
                      <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                                {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 20)}`} />)} 
                                <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                        {isReadMoreMap[item.p_id] ? ' Show Less' : ' ...Read More'}
                                </a>
                            </td>

                            <td>
    <span className="text-truncate">
        <label className="switch switch-primary switch-sm">
        <input
                type="checkbox"
                className="switch-input"
                onChange={() => handleChange(item.p_id, item.visibility)}
            />
            <span
                className={item.visibility === '1' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}
               
            ></span>
            <span className="switch-label"></span>
            <span className={ item.visibility === '1' ?"switch-handle-L" :"switch-handle-R"} ></span>
        </label>
        <span className="d-none">Out_of_Stock</span>
    </span>
</td>
                      <td>
                        <div className="dropdown">
                          <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" href={`editproduct?id=${item.p_id}`}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                            <a className="dropdown-item" onClick={() => removeProduct(item.p_id)}><i className="bx bx-trash me-1"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                   </>)}
                </tbody>
              </table>
              <Paginator first={first} rows={rows} totalRecords={data.data ? data.data.length : 0}  onPageChange={onPageChange} />
             
              
               </div>
          </div>
            : <div className="card">
            <h5 className="card-header">Arivomkadai products</h5>
            <div className="col-lg-3 ml-2">
            <input
              type="text"
              className="form-control"
              placeholder="Search by product name or category..."
              value={searchQuery}
              onChange={handleSearchInputChange}
            />
          </div>
            <div className="demo-inline-spacing" style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '-50px' }}>
              <a href="/addofflineproduct" className="btn btn-primary">
                <span className="tf-icons bx bx-plus"></span>&nbsp; Add Product
              </a>
            </div>
            <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover" width="100%">
                <thead>
                  <tr>
                     <th width="5%">Silder</th>
                    <th width="5%">Image</th>
				    <th width="5%">product</th>
                    <th>Content</th>
                    <th width="5%">Stock</th>
					<th width="5%">Preorder</th>
					<th width="5%">visibility</th>
                    <th width="5%">More</th>
                  </tr>
                </thead>
                <tbody className="table-border-bottom-0">
                {searchQuery && filteredData.length > 0 ? (
            <>  {filteredData.map(item => (
              <tr key={item.p_id}>
                 <td>
                  <input type="checkbox" checked={item.scroll === '0' ? false : true} onChange={() => toggleCheckboxs(item.p_id, item.scroll)} />
              </td>
                <td>


                  
                  {item.image ? (
                    <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
                  ) : (
                    <img  src={'https://api.arivomkadai.com/product_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
                  )}
                </td>
                <td>{item.product_code}<br></br>{item.category}<br></br>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</td>
                <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                          {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 20)}`} />)} 
                          <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                  {isReadMoreMap[item.p_id] ? ' Show Less' : ' ...Read More'}
                          </a>
                      </td>
                <td>{item.stock+' '+item.stockType}</td>
					   <td> <span className="text-truncate">
        <label className="switch switch-primary switch-sm">
        <input
                type="checkbox"
                className="switch-input"
                onChange={() => handleChanges(item.p_id, item.preorder)}
            />
            <span
                className={item.preorder === '1' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}
               
            ></span>
            <span className="switch-label"></span>
            <span className={ item.preorder === '1' ?"switch-handle-L" :"switch-handle-R"} ></span>
        </label>
        <span className="d-none">Out_of_Stock</span>
    </span></td>
                <td>
    <span className="text-truncate">
        <label className="switch switch-primary switch-sm">
        <input
                type="checkbox"
                className="switch-input"
                onChange={() => handleChanges(item.p_id, item.visibility)}
            />
            <span
                className={item.visibility === '1' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}
               
            ></span>
            <span className="switch-label"></span>
            <span className={ item.visibility === '1' ?"switch-handle-L" :"switch-handle-R"} ></span>
        </label>
        <span className="d-none">Out_of_Stock</span>
    </span>
</td>
                      
                <td>
               
                  <div className="dropdown">
                    <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                      <i className="bx bx-dots-vertical-rounded"></i>
                    </button>
                    <div className="dropdown-menu">
                      <a className="dropdown-item" href={`editofflineproduct?id=${item.p_id}`}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                      <a className="dropdown-item"  onClick={() => removeProducts(item.p_id)}><i className="bx bx-trash me-1"></i> Delete</a>
                    </div>
                  </div>
                </td>
              </tr>
            ))}
            </>
           ) : searchQuery && filteredData.length === 0 ? (
            <tr >
                         <td>No products found </td>
                       </tr>
         ) : (<>
                  {datas && datas.data && datas.data.slice(first, first + rows).map(item => (
                    <tr key={item.p_id}>
                       <td>
                  <input type="checkbox" checked={item.scroll === '0' ? false : true} onChange={() => toggleCheckboxs(item.p_id, item.scroll)} />
              </td>
                      <td>


                        
                        {item.image ? (
                          <img src={`https://api.arivomkadai.com/product_data/${item.image}`} alt="Avatar" className="w-px-50 h-px-80 rounded-circle" />
                        ) : (
                          <img  src={'https://api.arivomkadai.com/product_data/no_image.webp'}  alt="No Image" className="w-px-50 h-px-80 rounded-circle" />
                        )}
                      </td>
                      <td>{item.product_code}<br></br>{item.category}<br></br>{item.product.length > 50 ? `${item.product.slice(0, 50)}...` : item.product}</td>
                      <td className="text-wrap text-break" style={{  lineHeight: 1.5 }}>
                                {isReadMoreMap[item.p_id] ? (<ContentEditable html={`${item.content}`}/>) : (<ContentEditable html={`${item.content.slice(0, 20)}`} />)} 
                                <a className=" text-primary btn-outline-warning" onClick={() => toggleDescription(item.p_id)}>
                                        {isReadMoreMap[item.p_id] ? ' Show Less' : ' ...Read More'}
                                </a>
                            </td>
							<td>{item.stock+' '+item.stockType}</td>
					   <td> <span className="text-truncate">
        <label className="switch switch-primary switch-sm">
        <input
                type="checkbox"
                className="switch-input"
                onChange={() => handleChanges(item.p_id, item.preorder)}
            />
            <span
                className={item.preorder === '1' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}
               
            ></span>
            <span className="switch-label"></span>
            <span className={ item.preorder === '1' ?"switch-handle-L" :"switch-handle-R"} ></span>
        </label>
        <span className="d-none">Out_of_Stock</span>
    </span></td>
                            <td>
    <span className="text-truncate">
        <label className="switch switch-primary switch-sm">
        <input
                type="checkbox"
                className="switch-input"
                onChange={() => handleChanges(item.p_id, item.visibility)}
            />
            <span
                className={item.visibility === '1' ? ('switch-toggle-slider') : ('switch-toggle-slider-checked')}
               
            ></span>
            <span className="switch-label"></span>
            <span className={ item.visibility === '1' ?"switch-handle-L" :"switch-handle-R"} ></span>
        </label>
        <span className="d-none">Out_of_Stock</span>
    </span>
</td>
                      <td>
                        <div className="dropdown">
                          <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                            <i className="bx bx-dots-vertical-rounded"></i>
                          </button>
                          <div className="dropdown-menu">
                            <a className="dropdown-item" href={`editofflineproduct?id=${item.p_id}`}><i className="bx bx-edit-alt me-1"></i> Edit</a>
                            <a className="dropdown-item"  onClick={() => removeProducts(item.p_id)}><i className="bx bx-trash me-1"></i> Delete</a>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                   </>)}
                </tbody>
              </table>
              <Paginator first={first} rows={rows} totalRecords={datas.data ? datas.data.length : 0}  onPageChange={onPageChange} />
             
              
               </div>
          </div>}
        </ul>
            </div>
            <Footer />
            <div className="content-backdrop fade"></div>
          </div>
        </div>
      </div>
      <div className="layout-overlay layout-menu-toggle"></div>
    </div>
    </>
  );
}

export default Product;
