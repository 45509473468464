import React, { useState, useEffect } from 'react';
import Navbar from '../components/nav';
import Aside from '../components/aside';
import Footer from '../components/footer';
import DatePicker from 'react-date-picker';
import 'react-calendar/dist/Calendar.css';
import axios from 'axios';
import { Paginator } from 'primereact/paginator';
import { useNavigate } from "react-router-dom";
import '../App.css';
import { ReactSession } from 'react-client-session';

 function Couponpreorder() {
    const userID = ReactSession.get('userId');
    const [first, setFirst] = useState(0); // Index of the first row on the current page
    const [rows, setRows] = useState(15);
  const [coupon, setCoupon] = useState({});
  const navigate = useNavigate();
  const[input, setInput] = useState('');
  const [error, setError] = useState('');


  if(userID <= 0 ){
    navigate('/login');
 }
 const handleinputs = (event) => {
  
    const name = event.target.name;
    const value = event.target.value;
    setInput(values => ({...values, [name]: value}))
  }
  

  const handleSubmit = async(event) => {
    event.preventDefault();
    //console.log(input);
    try {
        const response = await axios.post('https://api.arivomkadai.com/couponpreorder/', input);
        
       // console.log(response.data);
        fetchData();
        event.target.reset();
      // navigate(`/addvariations?id=${response.data.productId}`);
    } catch (error) {
        // Handle error
        console.error('There was a problem with login:', error);
        setError('Invalid email or password');
    }
  }


 
    const fetchData = async () => {
      try {
        // Make API call to fetch old data
        const response = await axios.get(`https://api.arivomkadai.com/couponpreorder/`);
        setCoupon(response.data); 
       // console.log(response.data);
      } catch (error) {
        console.error('Error fetching old data:', error);
      }
    };

    useEffect(() => {
    fetchData();
  }, []);


  const removeCoupon = async (productId) => {
   // console.log('Deleting product with ID:', productId); // Log the product ID
    try {
      const response = await axios.put('https://api.arivomkadai.com/couponpreorder/', {id: productId});
      //console.log(response.data);
      fetchData();
     
    } catch (error) {
      console.error('Error deleting product:', error);
    }
  };
  
  

  const onPageChange = (event) => {
    setFirst(event.first);
    setRows(15); // Update rows to display only one row per page
  };


   //console.log(coupon.data);

  return (
    <div className="layout-wrapper layout-content-navbar">
            <div className="layout-container">
                <Aside/>
                <div className="layout-page">
                    <Navbar/>
                    <div className="content-wrapper">
                        <div className="container-xxl flex-grow-1 container-p-y">
                            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Arivomkadai Report</h4>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card mb-4">
                                        <h5 className="card-header">Arivomkadai Report</h5>
                                        <div className="card-body">
                                            <form id="formAccountSettings"  onSubmit={handleSubmit}>
                                                <div className="row">
                                                     <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Coupon Code</label>
                                                       
                                                        <input
                                                             className="form-control"
                                                             type="text"
                                                             id="code"
                                                             name="code"
                                                             value={input.code||''}
                                                             onChange={handleinputs}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Coupon Offer</label>
                                                       
                                                        <input
                                                             className="form-control"
                                                             type="text"
                                                             id="offer"
                                                             name="offer"
                                                             value={input.offer||''}
                                                             onChange={handleinputs}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Offer Upto</label>
                                                       
                                                        <input
                                                             className="form-control"
                                                             type="text"
                                                             id="upto"
                                                             name="upto"
                                                             value={input.upto||''}
                                                             onChange={handleinputs}
                                                        />
                                                    </div>


                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Offer Count</label>
                                                       
                                                        <input
                                                             className="form-control"
                                                             type="text"
                                                             id="count"
                                                             name="count"
                                                             value={input.count||''}
                                                             onChange={handleinputs}
                                                        />
                                                    </div>




                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">Start Date</label>
                                                       
                                                    <input aria-label="Date" type="date"   className="form-control"
                                                            id="name"
                                                            name="s_date"
                                                            required
                                                            onChange={handleinputs}
                                                            />
                                                    </div>


                                                    <div className="mb-3 col-md-3 col-xl-3 col-lg-3">
                                                        <label htmlFor="link" className="form-label">End Date</label>
                                                       
                                                    <input aria-label="Date" type="date"   className="form-control"
                                                            id="name"
                                                            name="e_date"
                                                            onChange={handleinputs}
                                                            required
                                                            />
                                                    </div>
                                                    
                                                   
													
                                                </div>
                                                <div className="mt-2">
                                                    <button type="submit" className="btn btn-primary me-2">Submit</button>
                                                </div>
                                            </form>




                                            <div className="container-xxl flex-grow-1 container-p-y ">
            <h4 className="fw-bold py-3 mb-4"><span className="text-muted fw-light"></span>Offline Reports</h4>
            <ul>
       
           <div className="card">
          <h5 className="card-header">Offline Reports</h5>
         
          <div className="table-responsive text-nowrap mt-2">
            <table className="table table-hover">
              <thead>
                <tr>
                <th>Coupon</th>
                <th>Discount</th>
                <th>Discount Upto</th>
                <th>Total Count</th>
                <th>Sold</th>
                <th>Balance</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Delete</th>
                
                </tr>
              </thead>
              <tbody className="table-border-bottom-0">
              {coupon && coupon.data && coupon.data.map(item => (
  <tr key={item.id}>
    <td>{item.code}</td>
    <td>{item.offer}</td>
    <td>{item.upto}</td>
    <td>{item.count}</td>
    <td>{item.sold}</td>
    <td>{item.balance}</td>
    <td>{item.s_date}</td>
    <td>{item.e_date}</td>
    <td><a  onClick={() => removeCoupon(item.id)} class='btn btn-danger bg-gradient-danger'> <i class="menu-icon tf-icons bx bx-trash"></i></a>
										</td>
  </tr>
))}
              </tbody>
            </table>
         <Paginator first={first} rows={rows} totalRecords={coupon.data ? coupon.data.length : 0}  onPageChange={onPageChange} />
             
             </div>
        </div>
      </ul>
          </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <Footer/>
                        <div className="content-backdrop fade"></div>
                    </div>
                </div>
            </div>
            <div className="layout-overlay layout-menu-toggle"></div>
        </div>
  )
}
export default Couponpreorder;
